import {
  ChevronUpIcon,
  Cross1Icon,
  MagnifyingGlassIcon,
} from '@radix-ui/react-icons';
import {
  Box,
  ChevronDownIcon,
  Flex,
  Popover,
  Text,
  TextField,
} from '@radix-ui/themes';
import { CommonChecklist } from 'components/common/checklist';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonInputHint } from 'components/common/form/hint';
import { CommonInputLabel } from 'components/common/form/label';
import { CommonInputWrapper } from 'components/common/form/wrapper';
import { t } from 'i18next';
import { ISearchInput } from 'interfaces/forms/search';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { RADIX } from 'lib_ts/enums/radix-ui';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { useMemo, useState } from 'react';
import slugify from 'slugify';

const AUTO_CLOSE_POPOVER = true;
const AUTO_CLEAR_SEARCH = false;
const MAX_ITEMS = 50;

interface ISearchOption extends IOption {
  searchKeys: string[];
}

const keyify = (v: string) => {
  const values = slugify(v, {
    lower: true,
    strict: true,
  }).split('-');

  return ArrayHelper.unique(values);
};

export const CommonSearchInput = (props: ISearchInput) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);

  const groupedOptions = useMemo(() => {
    const { options, skipSort, reverseSort } = props;

    const output: ISearchOption[] = [];

    const grouped = ArrayHelper.groupBy(options, 'group');

    const keys = Object.keys(grouped);

    if (!skipSort) {
      keys.sort();

      if (reverseSort) {
        keys.reverse();
      }
    }

    keys.forEach((key) => {
      const group = grouped[key];

      if (group.length === 0) {
        return;
      }

      if (key) {
        output.push({
          label: key,
          value: key,
          group: key,
          isGroup: true,
          disabled: true,
          hideControl: true,
          // for strict matching to find the full, unchanged group
          searchKeys: [key, ...keyify(key)],
        });
      }

      if (!skipSort) {
        group.sort((a, b) => a.label.localeCompare(b.label));

        if (reverseSort) {
          group.reverse();
        }
      }

      output.push(
        ...group.map((m) => {
          const o: ISearchOption = {
            ...m,
            searchKeys: [
              // for strict matching to find the full, unchanged value
              m.value,
              ...keyify(
                [m.value, m.label, m.group ?? '', ...(m.aliases ?? [])].join(
                  ' '
                )
              ),
            ],
          };

          return o;
        })
      );
    });

    return output;
  }, [props.options, props.skipSort, props.reverseSort]);

  const slotIcon = useMemo(() => {
    if (props.disabled) {
      // don't show any icon since the user can't use it anyway
      return undefined;
    }

    if (props.optional && props.values && props.values.length > 0) {
      return (
        <Cross1Icon
          onClick={() => {
            if (props.disabled) {
              return;
            }

            props.onChange([]);
          }}
        />
      );
    }

    if (open) {
      return <ChevronUpIcon />;
    }

    return (
      <ChevronDownIcon
        onClick={() => {
          if (props.disabled) {
            return;
          }

          setOpen(true);
        }}
      />
    );
  }, [props.disabled, props.optional, props.values, props.onChange, open]);

  const valueDisplay = useMemo(() => {
    if (!props.values) {
      return '';
    }

    if (props.values.length === 0) {
      return '';
    }

    if (props.values.length === 1) {
      const firstValue = props.values[0];
      return (
        props.options.find((o) => o.value === firstValue)?.label ?? firstValue
      );
    }

    // todo: add translation key for this
    return `${props.values.length} options selected`;
  }, [props.values, props.options]);

  const placeholder = t(
    props.placeholder ??
      (props.hideSearch
        ? 'common.select-placeholder'
        : 'common.search-placeholder')
  ).toString();

  const matchingOptions = useMemo(() => {
    const safeSearchTerms = props.strict ? [searchTerm] : keyify(searchTerm);

    if (safeSearchTerms.length === 0) {
      return groupedOptions;
    }

    return groupedOptions.filter((o) =>
      ArrayHelper.hasSubstringIntersection(o.searchKeys, safeSearchTerms)
    );
  }, [props.strict, searchTerm, groupedOptions]);

  return (
    <ErrorBoundary componentName="CommonSearchInput">
      <CommonInputWrapper {...props}>
        <CommonInputLabel {...props} />

        <Popover.Root
          open={open}
          onOpenChange={(value) => {
            setOpen(value);

            if (AUTO_CLEAR_SEARCH && !value) {
              // clear search on popover close
              setSearchTerm('');
            }
          }}
        >
          <Popover.Trigger disabled={props.disabled}>
            <TextField.Root
              className={`cursor-pointer ${props.className ?? ''}`}
              disabled={props.disabled}
              color={props.inputColor}
              name={props.name}
              placeholder={placeholder}
              required={!props.optional}
              value={valueDisplay}
              onChange={() => {
                // onChange provided to suppress error re: value w/o onChange
              }}
              // prevent the cursor from entering this input (on click or clear) so users don't think they can type into it
              onFocus={(event) => event.currentTarget.blur()}
              type="text"
            >
              <TextField.Slot side="right">{slotIcon}</TextField.Slot>
            </TextField.Root>
          </Popover.Trigger>

          <Popover.Content minWidth="300px">
            {/* for performance, don't draw the stuff unless the search is open */}
            {open && (
              <Flex direction="column" gap={RADIX.FLEX.GAP.INPUT}>
                {!props.hideSearch && (
                  <>
                    <Box>
                      <TextField.Root
                        className={props.className}
                        disabled={props.disabled}
                        name={props.name}
                        type="text"
                        value={searchTerm}
                        placeholder={
                          props.options.length > MAX_ITEMS
                            ? 'Type to find more options'
                            : undefined
                        }
                        onChange={(e) => {
                          const v = e.target.value;
                          setSearchTerm(v);
                        }}
                      >
                        <TextField.Slot side="right">
                          {searchTerm ? (
                            <Cross1Icon
                              className="cursor-pointer"
                              onClick={() => setSearchTerm('')}
                            />
                          ) : (
                            <MagnifyingGlassIcon />
                          )}
                        </TextField.Slot>
                      </TextField.Root>
                    </Box>

                    {matchingOptions.length === 0 && (
                      <Box>
                        <Text color={RADIX.COLOR.SECONDARY}>
                          No results found.
                        </Text>
                      </Box>
                    )}
                  </>
                )}

                <Box
                  maxHeight="250px"
                  maxWidth="40ch"
                  overflowX="hidden"
                  overflowY="scroll"
                >
                  <CommonChecklist
                    id={`${props.id}-search-checklist`}
                    options={matchingOptions}
                    onChange={(v) => {
                      props.onChange(v);

                      if (AUTO_CLOSE_POPOVER && !props.multiple) {
                        setOpen(false);
                      }
                    }}
                    values={props.values}
                    as={props.multiple ? 'checkbox' : 'button'}
                    truncateTo={MAX_ITEMS}
                  />
                </Box>
              </Flex>
            )}
          </Popover.Content>
        </Popover.Root>

        <CommonInputHint {...props} />
      </CommonInputWrapper>
    </ErrorBoundary>
  );
};
