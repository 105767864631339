import { Flex, Separator, Text } from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import {
  MIN_MANUAL_TRAIN_SHOTS,
  MIN_QUICK_TRAIN_SHOTS,
} from 'classes/plate-canvas';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonSwitchInput } from 'components/common/form/switch';
import { SettingForm } from 'components/common/settings-dialog/form';
import { SettingRow } from 'components/common/settings-dialog/row';
import env from 'config';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { IMachineContext } from 'contexts/machine.context';
import { CookieKey, DragDropEngine } from 'enums/cookies.enums';
import { t } from 'i18next';
import { fallbackAppearance, setAppearance } from 'index';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { RadixAppearance } from 'lib_ts/enums/radix-ui';
import { LANGUAGE_OPTIONS, LanguageCode } from 'lib_ts/enums/translation';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import React from 'react';

const COMPONENT_NAME = 'InterfaceSection';

const minShotsForMode = (mode: TrainingMode): number => {
  switch (mode) {
    case TrainingMode.Manual: {
      return MIN_MANUAL_TRAIN_SHOTS;
    }

    case TrainingMode.Quick:
    default: {
      return MIN_QUICK_TRAIN_SHOTS;
    }
  }
};

interface IProps {
  cookiesCx: ICookiesContext;
  machineCx: IMachineContext;
  authCx: IAuthContext;
}

interface IState {
  // include user's language, in case they are using an env that isn't their usual
  languages: IOption[];
}

export class InterfaceSection extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      languages: LANGUAGE_OPTIONS.filter(
        (o) =>
          // always include the user's language even if not enabled for this env
          props.authCx.current.language === o.value ||
          // include all languages enabled for this env
          env.languages.includes(o.value)
      ),
    };
  }

  render() {
    const IS_TOUCH_DEVICE = navigator.maxTouchPoints > 0;
    const appearance = fallbackAppearance(RadixAppearance.Dark);

    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <SettingForm>
          {this.state.languages.length > 1 && (
            <>
              <SettingRow
                header="common.interface-language"
                description={
                  <Text>{t('common.interface-language-description')}</Text>
                }
                input={
                  <CommonSelectInput
                    id="interface-language"
                    name="language"
                    data-value={this.props.authCx.current.language}
                    options={this.state.languages}
                    value={this.props.authCx.current.language}
                    onChange={(v) => {
                      const code = v as LanguageCode;

                      // updating the current user will automatically cascade to i18n language upon success
                      this.props.authCx.updateUser({
                        _id: this.props.authCx.current.userID,
                        language: code,
                      });
                    }}
                  />
                }
              />
              <Separator size="4" />
            </>
          )}

          <SettingRow
            header="settings.appearance"
            description={<Text>{t('settings.appearance-msg')}</Text>}
            input={
              <CommonSelectInput
                id="interface-appearance"
                name="appearance"
                data-value={appearance}
                className="capitalize"
                options={[
                  {
                    label: t('settings.dark-mode'),
                    value: RadixAppearance.Dark,
                  },
                  {
                    label: t('settings.light-mode'),
                    value: RadixAppearance.Light,
                  },
                  {
                    label: t('settings.system-mode'),
                    value: RadixAppearance.Inherit,
                  },
                ]}
                value={appearance}
                onChange={(v) => {
                  setAppearance({
                    value: v as RadixAppearance,
                    autoReload: false,
                  });
                }}
              />
            }
          />

          <Separator size="4" />

          <SettingRow
            header="common.training-mode"
            description={<Text>{t('settings.training-mode-msg')}</Text>}
            input={
              <CommonSelectInput
                id="interface-training-mode"
                name="training_mode"
                data-value={this.props.authCx.current.training_mode}
                className="capitalize"
                options={[
                  {
                    label: t('settings.auto-train'),
                    value: TrainingMode.Quick,
                  },
                  {
                    label: t('settings.manual-train'),
                    value: TrainingMode.Manual,
                  },
                ]}
                value={this.props.authCx.current.training_mode}
                onChange={(v) => {
                  const mode = v as TrainingMode;
                  const shots = minShotsForMode(mode);

                  // save the setting to the database (to persist across sessions)
                  this.props.authCx.updateUser({ training_mode: mode });

                  // update the setting to the cookies
                  this.props.cookiesCx.setCookie(CookieKey.app, {
                    shots: shots,
                  });

                  // only update machine if user is active
                  if (this.props.machineCx.checkActive(true)) {
                    const m: IMachine = {
                      ...this.props.machineCx.machine,
                      training_threshold: shots,
                    };

                    this.props.machineCx.update(m);
                  }
                }}
              />
            }
          />

          {IS_TOUCH_DEVICE && (
            <>
              <Separator size="4" />
              <SettingRow
                header="common.drag-drop-mode"
                description={<Text>{t('settings.drag-drop-mode-msg')}</Text>}
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="interface-touch"
                      checked={
                        this.props.cookiesCx.app.drag_drop_engine ===
                        DragDropEngine.touch
                      }
                      onCheckedChange={(v) => {
                        this.props.cookiesCx.setCookie(CookieKey.app, {
                          drag_drop_engine: v
                            ? DragDropEngine.touch
                            : DragDropEngine.click,
                        });

                        NotifyHelper.info({
                          message_md: t('settings.refresh-page-for-effect-msg'),
                          delay_ms: 0,
                          inbox: true,
                        });
                      }}
                    />
                  </Flex>
                }
              />
            </>
          )}
          <Separator size="4" />
          <SettingRow
            header="common.placeholder-folders"
            description={<Text>{t('settings.placeholder-folders-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-placeholder-folders"
                  data-testid="PlaceholderFoldersToggle"
                  checked={this.props.authCx.current.placeholder_folders}
                  onCheckedChange={(v) => {
                    this.props.authCx.updateUser({
                      placeholder_folders: v,
                    });
                  }}
                />
              </Flex>
            }
          />

          {env.enable.toggle_plate_ellipses && (
            <>
              <Separator size="4" />
              <SettingRow
                header="common.plate-ellipses"
                description={<Text>{t('settings.plate-ellipses-msg')}</Text>}
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="interface-show-ellipses"
                      checked={this.props.authCx.current.plate_show_ellipses}
                      onCheckedChange={(v) => {
                        this.props.authCx.updateUser({
                          plate_show_ellipses: v,
                        });
                      }}
                    />
                  </Flex>
                }
              />
            </>
          )}

          {env.enable.toggle_plate_debug && (
            <>
              <Separator size="4" />
              <SettingRow
                header="common.plate-debug"
                description={<Text>{t('settings.plate-debug-msg')}</Text>}
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="interface-show-plate-debug"
                      checked={this.props.cookiesCx.app.plate_show_debug}
                      onCheckedChange={(v) =>
                        this.props.cookiesCx.setCookie(CookieKey.app, {
                          plate_show_debug: v,
                        })
                      }
                    />
                  </Flex>
                }
              />
            </>
          )}

          {env.enable.toggle_plate_safety_controls && (
            <>
              <Separator size="4" />
              <SettingRow
                header="common.safety-limits"
                description={<Text>{t('settings.safety-limits-msg')}</Text>}
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="interface-ellipse-limits"
                      checked={this.props.cookiesCx.app.plate_limit_by_ellipse}
                      onCheckedChange={(v) =>
                        this.props.cookiesCx.setCookie(CookieKey.app, {
                          plate_limit_by_ellipse: v,
                        })
                      }
                    />
                  </Flex>
                }
              />
              <Separator size="4" />
              <SettingRow
                header="common.safety-warnings"
                description={<Text>{t('settings.safety-warnings-msg')}</Text>}
                input={
                  <Flex justify="end">
                    <CommonSwitchInput
                      id="interface-ellipse-warnings"
                      checked={
                        this.props.cookiesCx.app.plate_show_ellipse_warnings
                      }
                      onCheckedChange={(v) =>
                        this.props.cookiesCx.setCookie(CookieKey.app, {
                          plate_show_ellipse_warnings: v,
                        })
                      }
                    />
                  </Flex>
                }
              />
            </>
          )}
          <Separator size="4" />
          <SettingRow
            header="common.preview-features"
            description={<Text>{t('settings.preview-features-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-beta"
                  // always checked and disabled while on beta env
                  disabled={env.identifier === 'beta'}
                  checked={
                    env.identifier === 'beta' ||
                    this.props.authCx.current.enable_beta
                  }
                  onCheckedChange={(v) => {
                    this.props.authCx.updateUser({
                      enable_beta: v,
                    });
                  }}
                />
              </Flex>
            }
          />
          <Separator size="4" />
          <SettingRow
            header="common.debug-messages"
            description={<Text>{t('settings.debug-messages-msg')}</Text>}
            input={
              <Flex justify="end">
                <CommonSwitchInput
                  id="interface-debug-toasts"
                  checked={this.props.cookiesCx.app.enable_debug_toasts}
                  onCheckedChange={(v) => {
                    this.props.cookiesCx.setCookie(CookieKey.app, {
                      enable_debug_toasts: v,
                    });
                  }}
                />
              </Flex>
            }
          />
        </SettingForm>
      </ErrorBoundary>
    );
  }
}
