import { MlbStatsHelper } from 'classes/helpers/mlb-stats.helper';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { IAuthContext } from 'contexts/auth.context';
import { ICookiesContext } from 'contexts/cookies.context';
import { t } from 'i18next';
import { IGameFilter, IPitchFilter } from 'interfaces/i-mlb-browse';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { getMergedMSDict } from 'lib_ts/classes/ms.helper';
import { CURRENT_MLB_SEASON } from 'lib_ts/enums/mlb.enums';
import { lookupPitchType } from 'lib_ts/enums/pitches.enums';
import { IOption } from 'lib_ts/interfaces/common/i-option';
import { IMachine } from 'lib_ts/interfaces/i-machine';
import { IMlbGame } from 'lib_ts/interfaces/mlb-stats-api/i-game';
import { IMlbPitchExt } from 'lib_ts/interfaces/mlb-stats-api/i-pitch';
import {
  IMlbPlayer,
  IMlbPlayerExt,
} from 'lib_ts/interfaces/mlb-stats-api/i-player';
import { IBuildPitchChars, IPitch } from 'lib_ts/interfaces/pitches';
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MlbStatsService } from 'services/mlb-stats.service';
import { StateTransformService } from 'services/state-transform.service';

export interface IMlbBrowseContext {
  loading: boolean;

  normalizeRelease: boolean;
  setNormalizeRelease: (value: boolean) => void;

  gameFilter: Partial<IGameFilter>;
  readonly mergeGameFilter: (value: Partial<IGameFilter>) => void;

  pitchFilter: Partial<IPitchFilter>;
  readonly mergePitchFilter: (value: Partial<IPitchFilter>) => void;

  seasons: IOption[];
  seasonGames: IMlbGame[];
  seasonPlayers: IMlbPlayer[];

  gamePlays: IMlbPitchExt[];

  filteredGames: IMlbGame[];
  filteredPitches: IMlbPitchExt[];

  readonly setPitches: (plays: IMlbPitchExt[]) => void;

  // callback is run if the toast is shown and the user presses accept
  readonly checkLaneWarning: (onAccept: () => void) => boolean;

  readonly buildPitches: (config: {
    machine: IMachine;
    pitches: IMlbPitchExt[];
    isAverage?: boolean;
  }) => Promise<IPitch[]>;
}

const DEFAULT: IMlbBrowseContext = {
  loading: false,

  normalizeRelease: true,
  setNormalizeRelease: () => console.debug('not init'),

  gameFilter: {
    season: CURRENT_MLB_SEASON,
  },
  mergeGameFilter: () => console.debug('not init'),

  pitchFilter: {},
  mergePitchFilter: () => console.debug('not init'),

  seasons: [],

  seasonGames: [],

  seasonPlayers: [],

  gamePlays: [],

  filteredGames: [],
  filteredPitches: [],

  setPitches: () => console.debug('not init'),

  checkLaneWarning: () => false,

  buildPitches: async () => [],
};

export const MlbBrowseContext = createContext(DEFAULT);

interface IProps {
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  children: ReactNode;
}

export const MlbBrowseProvider: FC<IProps> = (props) => {
  const [_warningAccepted, _setWarningAccepted] = useState(false);
  const [_warningVisible, _setWarningVisible] = useState(false);

  const [_loading, _setLoading] = useState(DEFAULT.loading);
  const [_normalizeRelease, _setNormalizeRelease] = useState(
    DEFAULT.normalizeRelease
  );

  const [_gameFilter, _setGameFilter] = useState(DEFAULT.gameFilter);
  const [_pitchFilter, _setPitchFilter] = useState(DEFAULT.pitchFilter);

  const [_seasons, _setSeasons] = useState(DEFAULT.seasons);
  const [_seasonGames, _setSeasonGames] = useState(DEFAULT.seasonGames);
  const [_seasonPlayers, _setSeasonPlayers] = useState(DEFAULT.seasonPlayers);

  const [_gamePlayers, _setGamePlayers] = useState<IMlbPlayerExt[]>([]);

  const [_pitches, _setPitches] = useState<IMlbPitchExt[]>([]);

  const _initialize = async () => {
    try {
      _setLoading(true);

      const seasons = await MlbStatsService.getInstance().countGamesPerSeason();

      _setSeasons(
        seasons
          .filter((s) => s.total > 0)
          .sort((a, b) => (a.season > b.season ? -1 : 1))
          .map((o) => ({
            label: o.season.toString(),
            value: o.season.toString(),
          }))
      );
    } catch (e) {
      console.error(e);
    } finally {
      _setLoading(false);
    }
  };

  const _mergeGameFilter = (value: Partial<IGameFilter>) =>
    _setGameFilter({
      ..._gameFilter,
      ...value,
    });

  const _mergePitchFilter = (value: Partial<IPitchFilter>) =>
    _setPitchFilter({
      ..._pitchFilter,
      ...value,
    });

  // get players when season changes
  useEffect(() => {
    if (!_gameFilter.season) {
      _setSeasonPlayers([]);
      return;
    }

    MlbStatsService.getInstance()
      .getPlayersForSeason(_gameFilter.season)
      .then((result) => {
        _setSeasonPlayers(result);
      })
      .catch((error) => {
        console.error(error);
        _setSeasonPlayers([]);
      });
  }, [_gameFilter.season]);

  // get games when season changes
  useEffect(() => {
    if (!_gameFilter.season) {
      _setSeasonGames([]);
      return;
    }

    MlbStatsService.getInstance()
      .getGamesForSeason(_gameFilter.season)
      .then((result) => {
        result.sort((a, b) => -a.officialDate.localeCompare(b.officialDate));
        _setSeasonGames(result);
      })
      .catch((error) => {
        console.error(error);
        _setSeasonGames([]);
      });
  }, [_gameFilter.season]);

  // loads pitches, then loads playrs, then uses both to determine if each pitcher is on the home vs away team
  const _loadData = async () => {
    const _fetchPitches = async (): Promise<IMlbPitchExt[]> => {
      if (!_gameFilter.season) {
        NotifyHelper.warning({
          message_md: `Please select a season to fetch relevant pitches.`,
        });
        return [];
      }

      if (
        !_gameFilter.gamePk &&
        (!_gameFilter.pitchers || _gameFilter.pitchers.length === 0) &&
        (!_gameFilter.batters || _gameFilter.batters.length === 0)
      ) {
        NotifyHelper.warning({
          message_md: `Please select a pitcher, a hitter, or a game to fetch relevant pitches.`,
        });
        return [];
      }

      return MlbStatsService.getInstance().getPitches({
        season: _gameFilter.season,
        gamePk: _gameFilter.gamePk,
        pitcherPk: _gameFilter.pitchers?.[0]?.playerPk,
        batterPk: _gameFilter.batters?.[0]?.playerPk,
      });
    };

    const _fetchGamePlayers = async (): Promise<IMlbPlayerExt[]> => {
      if (!_gameFilter.season) {
        // can't load without a season
        NotifyHelper.warning({
          message_md: `Please select a season to fetch relevant players.`,
        });
        return [];
      }

      if (!_gameFilter.gamePk) {
        // i.e. only a pitcher is selected, no specific game is selected
        return [];
      }

      return MlbStatsService.getInstance()
        .getPlayersForGame(_gameFilter.gamePk)
        .then((result) => {
          result.sort((a, b) => a.name.localeCompare(b.name));
          return result as IMlbPlayerExt[];
        });
    };

    try {
      _setLoading(true);

      const guids = await _fetchPitches();
      if (!guids) {
        throw new Error('Failed to fetch pitches.');
      }

      const players = await _fetchGamePlayers();
      if (!players) {
        throw new Error('Failed to fetch players.');
      }

      players?.forEach((player) => {
        // use sample pitch guid to determine if the player is on the home vs away team for this game
        const samplePitch = guids.find((g) => g.pitcherPk === player.playerPk);
        if (samplePitch?.count) {
          player.isHome = samplePitch.count.isTopInning;
          return;
        }

        // couldn't find a pitch, look for first at bat guid
        const sampleBat = guids.find((g) => g.batterPk === player.playerPk);
        player.isHome = !sampleBat?.count.isTopInning;
      });

      _setPitches(guids);
      _setGamePlayers(players);
    } catch (e) {
      console.error(e);

      _setPitches([]);
      _setGamePlayers([]);
    } finally {
      _setLoading(false);
    }
  };

  const _filteredGames = useMemo(() => {
    const pitcherPk = _gameFilter.pitchers?.[0]?.playerPk;
    const batterPk = _gameFilter.batters?.[0]?.playerPk;
    const teamPk = _gameFilter.teamPk;

    const output = _seasonGames
      .filter((g) => !pitcherPk || g.pitcherPks.includes(pitcherPk))
      .filter((g) => !batterPk || g.batterPks.includes(batterPk))
      .filter(
        (g) => !teamPk || g.home.teamPk === teamPk || g.away.teamPk === teamPk
      );

    return output;
  }, [_gameFilter, _seasonGames]);

  const _filteredPitches = useMemo(() => {
    return _pitches
      .filter(
        (p) =>
          _gameFilter.gamePk === undefined || p.gamePk === _gameFilter.gamePk
      )
      .filter(
        (p) =>
          _pitchFilter.isHome === undefined ||
          // home team always pitches at the top, and hits at the bottom of innings
          (_pitchFilter.isHome === 'home') === p.count.isTopInning
      )
      .filter(
        (p) =>
          !_gameFilter.pitchers ||
          _gameFilter.pitchers.length === 0 ||
          _gameFilter.pitchers[0].playerPk === p.pitcherPk
      )
      .filter(
        (p) =>
          !_gameFilter.batters ||
          _gameFilter.batters.length === 0 ||
          _gameFilter.batters[0].playerPk === p.batterPk
      )
      .filter(
        (p) =>
          !_pitchFilter.pitchType ||
          _pitchFilter.pitchType === lookupPitchType(p.type)
      )
      .filter(
        (p) => !_pitchFilter.pitchHand || _pitchFilter.pitchHand === p.pitchHand
      )
      .filter(
        (p) => !_pitchFilter.batSide || _pitchFilter.batSide === p.batSide
      )
      .filter(
        (p) =>
          !_pitchFilter.outcomeType || _pitchFilter.outcomeType === p.outcome
      );
  }, [_pitches, _gameFilter, _pitchFilter]);

  const state: IMlbBrowseContext = {
    loading: _loading,

    normalizeRelease: _normalizeRelease,
    setNormalizeRelease: _setNormalizeRelease,

    gameFilter: _gameFilter,
    mergeGameFilter: _mergeGameFilter,

    pitchFilter: _pitchFilter,
    mergePitchFilter: _mergePitchFilter,

    seasons: _seasons,
    seasonGames: _seasonGames,
    seasonPlayers: _seasonPlayers,

    gamePlays: _pitches,

    filteredGames: _filteredGames,
    filteredPitches: _filteredPitches,

    setPitches: _setPitches,

    checkLaneWarning: (onAccept) => {
      if (_warningAccepted) {
        // allow user to S2M
        return true;
      }

      if (_warningVisible) {
        // toast is already visible
        return false;
      }

      // prevents subsequent triggers while toast is open from spawning additional toasts
      _setWarningVisible(true);

      NotifyHelper.warning({
        message_md: `Before test firing, please ensure the lane is clear for safety reasons.`,
        delay_ms: 0,
        // if the user dismisses without accepting, the next trigger will resurface the toast
        onClose: () => _setWarningVisible(false),
        buttons: [
          {
            label: t('common.accept'),
            dismissAfterClick: true,
            onClick: () => {
              // user will not be prompted again for the rest of the session
              _setWarningAccepted(true);

              // e.g. re-trigger S2M
              onAccept();
            },
          },
        ],
      });

      return false;
    },

    buildPitches: async (config): Promise<IPitch[]> => {
      const validGuids: IMlbPitchExt[] = [];
      const errors: string[] = [];

      config.pitches.forEach((g) => {
        const errs = MlbStatsHelper.getErrors(g);
        if (errs.length === 0) {
          validGuids.push(g);
        } else {
          errors.push(...errs);
        }
      });

      if (errors.length > 0) {
        NotifyHelper.warning({
          message_md: [
            'Invalid data detected:',
            ArrayHelper.unique(errors)
              .map((e) => ` - ${e}`)
              .join('\n'),
            `${
              config.pitches.length === 1 ? 'This pitch' : 'Affected pitches'
            } cannot be constructed at this time.`,
          ].join('\n\n'),
        });
      }

      if (validGuids.length === 0) {
        return [];
      }

      const convertedResults = validGuids.map((g) =>
        // average pitches all point to the default plate
        MlbStatsHelper.convertToChars(
          g,
          props.cookiesCx.app.pitch_upload_options.priority,
          !!config.isAverage
        )
      );

      const withWarnings = convertedResults.filter(
        (b) => b.warnings.length > 0
      );

      if (withWarnings.length > 0) {
        const msgs = ArrayHelper.unique(
          withWarnings.flatMap((w) => w.warnings)
        ).map((txt) => ` - ${txt}`);

        NotifyHelper.warning({
          message_md: [
            `${
              withWarnings.length > 1 ? 'Some pitches' : 'One pitch'
            } required minor adjustment(s) to be usable on ${
              config.machine.machineID
            }:`,
            msgs.join('\n'),
          ].join('\n\n'),
        });
      }

      if (_normalizeRelease) {
        MlbStatsHelper.averageReleasesByPitcher(validGuids, convertedResults);
      }

      const buildableResults = convertedResults.filter((g) => !!g.chars);

      if (buildableResults.length === 0) {
        return [];
      }

      if (buildableResults.length > 1) {
        // avoid showing loading for single pitch builds
        _setLoading(true);
      }

      const chars = await StateTransformService.getInstance()
        .buildPitches({
          machine: config.machine,
          pitches: buildableResults.map(
            (r) => r.chars
          ) as Partial<IBuildPitchChars>[],
          notifyError: true,
        })
        .finally(() => _setLoading(false));

      return chars
        .map((char) => {
          if (!char.bs) {
            return;
          }

          if (!char.traj) {
            return;
          }

          if (!char.ms) {
            return;
          }

          const play = validGuids.find((g) => g.guid === char.mongo_id);
          if (!play) {
            return;
          }

          const gamePk = play.gamePk;
          const game = _filteredGames.find((g) => g.gamePk === gamePk);

          const pitchType = lookupPitchType(play.type);

          const pitch: IPitch = {
            mlb_gamePk: play.gamePk,
            mlb_guid: play.guid,

            hitter: config.isAverage ? undefined : play.batter,
            game: game
              ? `${game.officialDate}: ${game.away.name} @ ${game.home.name}`
              : undefined,
            outcome: config.isAverage ? undefined : play.outcome,

            bs: char.bs,
            traj: char.traj,
            msDict: getMergedMSDict(config.machine, [char.ms]),
            plate_loc_backup: char.plate,
            seams: char.seams,
            breaks: char.breaks,

            priority: props.cookiesCx.app.pitch_upload_options.priority,

            name: [
              config.isAverage ? 'Avg.' : undefined,
              play.pitcher,
              pitchType,
              // char.bs.vnet.toFixed(0),
            ]
              .filter((s) => !!s)
              .join(' '),

            type: pitchType,

            // year: this.props.browseCx.gameFilter.season,

            _id: play.guid,
            _created: new Date().toISOString(),
            _changed: new Date().toISOString(),
            _parent_def: '',
            _parent_id: '',
          };

          return pitch;
        })
        .filter((c) => !!c) as IPitch[];
    },
  };

  useEffect(() => {
    if (props.authCx.current.mlb_stats_api) {
      _initialize();
    }
  }, [props.authCx.current.mlb_stats_api]);

  useEffect(() => {
    // clear pitches while players and games load
    _setPitches([]);

    // clear filters since the options will be different
    _mergeGameFilter({
      teamPk: undefined,
      pitchers: undefined,
      batters: undefined,
      gamePk: undefined,
    });
  }, [_gameFilter.season]);

  // determine whether we have enough inputs to load pitches
  useEffect(() => {
    const pitcherPk = _gameFilter.pitchers?.[0]?.playerPk;
    const batterPk = _gameFilter.batters?.[0]?.playerPk;

    if (
      _gameFilter.gamePk === undefined &&
      pitcherPk === undefined &&
      batterPk === undefined
    ) {
      return;
    }

    _loadData();
  }, [_gameFilter.gamePk, _gameFilter.pitchers, _gameFilter.batters]);

  // determine whether the game filter needs to be reset
  useEffect(() => {
    try {
      const game = _seasonGames.find((g) => g.gamePk === _gameFilter.gamePk);

      if (!game) {
        throw new Error(`Failed to find game ${_gameFilter.gamePk}`);
      }

      const teamPk = _gameFilter.teamPk;
      if (teamPk && ![game.home.teamPk, game.away.teamPk].includes(teamPk)) {
        throw new Error(`Selected game does not involve the selected team`);
      }

      const pitcherPk = _gameFilter.pitchers?.[0]?.playerPk;
      if (pitcherPk && !game.pitcherPks.includes(pitcherPk)) {
        throw new Error(`Selected game does not involve the selected pitcher`);
      }

      const batterPk = _gameFilter.batters?.[0]?.playerPk;
      if (batterPk && !game.batterPks.includes(batterPk)) {
        throw new Error(`Selected game does not involve the selected batter`);
      }
    } catch {
      // if we came here, the current game should be deselected
      _mergeGameFilter({
        gamePk: undefined,
      });
    }
  }, [_gameFilter.teamPk, _gameFilter.pitchers, _gameFilter.batters]);

  return (
    <MlbBrowseContext.Provider value={state}>
      {props.children}
    </MlbBrowseContext.Provider>
  );
};
