import {
  CaretRightIcon,
  CheckCircledIcon,
  CopyIcon,
  CrossCircledIcon,
  DownloadIcon,
  InputIcon,
  LoopIcon,
  Pencil2Icon,
  ReloadIcon,
  ResetIcon,
  RowsIcon,
  ShuffleIcon,
  TrashIcon,
  UpdateIcon,
  UploadIcon,
} from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Flex,
  Grid,
  Spinner,
  Text,
} from '@radix-ui/themes';
import { NotifyHelper } from 'classes/helpers/notify.helper';
import { PitchDesignHelper } from 'classes/helpers/pitch-design.helper';
import { PitchListHelper } from 'classes/helpers/pitch-list.helper';
import { WebSocketHelper } from 'classes/helpers/web-socket.helper';
import { CustomIcon } from 'components/common/custom-icon';
import {
  BaseballIcon,
  BetaIcon,
  SuperAdminIcon,
} from 'components/common/custom-icon/shorthands';
import { CommonConfirmationDialog } from 'components/common/dialogs/confirmation';
import { CopyPitchesDialog } from 'components/common/dialogs/copy-pitches';
import { PitchDataDialog } from 'components/common/dialogs/pitch-data';
import { IDropHandle, IDropValue } from 'components/common/drag-drop';
import { ErrorBoundary } from 'components/common/error-boundary';
import { CommonSimpleFileUploader } from 'components/common/file-uploader';
import { CommonSelectInput } from 'components/common/form/select';
import { CommonTextInput } from 'components/common/form/text';
import { CommonContentWithSidebar } from 'components/common/layout/content-with-sidebar';
import { FlexTableWrapper } from 'components/common/layout/flex-table-wrapper';
import { RemoteKeydownListener } from 'components/common/listeners/remote-keydown';
import { CommonLogs, ILog } from 'components/common/logs';
import { ManageCardDialog } from 'components/common/pitch-lists/manage-card';
import { ManageListDialog } from 'components/common/pitch-lists/manage-list';
import { CommonTable } from 'components/common/table';
import { CommonTableButton } from 'components/common/table/button';
import {
  ITableContext,
  TableContext,
  TableProvider,
} from 'components/common/table/context';
import { CommonTooltip } from 'components/common/tooltip';
import { ActiveCalibrationModelWarning } from 'components/common/warnings/active-calibration-model-warning';
import { MachineCalibrateButton } from 'components/machine/buttons/calibrate';
import { MachineFireButton } from 'components/machine/buttons/fire';
import { MachineUnavailableButton } from 'components/machine/buttons/unavailable';
import { PresetTrainingDialog } from 'components/machine/dialogs/preset-training';
import { TrainingDialog } from 'components/machine/dialogs/training';
import {
  ChangeVideoDialog,
  DeletePitchesDialog,
  EditBreaksDialog,
  EditPitchDialog,
  EditSpinsDialog,
  OptimizePitchDialog,
  RefreshListDialog,
  RenameFolderDialog,
  ResetTrainingDialog,
  SearchPitchesDialog,
} from 'components/sections/pitch-list/dialogs';
import { Header } from 'components/sections/pitch-list/header';
import { PitchListSidebar } from 'components/sections/pitch-list/sidebar';
import { PitchesHeader } from 'components/sections/pitches/header';
import env from 'config';
import { AimingContext, IAimingContext } from 'contexts/aiming.context';
import { AuthContext, IAuthContext } from 'contexts/auth.context';
import { CookiesContext, ICookiesContext } from 'contexts/cookies.context';
import { GlobalContext, IGlobalContext } from 'contexts/global.context';
import { HittersContext, IHittersContext } from 'contexts/hitters.context';
import {
  CheckedContext,
  CheckedProvider,
  ICheckedContext,
} from 'contexts/layout/checked.context';
import { IMachineContext, MachineContext } from 'contexts/machine.context';
import {
  IPitchListContext,
  MAX_SEARCH_LIMIT,
  PitchListContext,
  SEARCH_ID,
} from 'contexts/pitch-lists/list.context';
import {
  IPitchListsContext,
  PitchListsContext,
} from 'contexts/pitch-lists/lists.context';
import {
  IMatchingShotsContext,
  MatchingShotsContext,
  ONLY_ALLOW_REFRESH_ON_TRAIN,
} from 'contexts/pitch-lists/matching-shots.context';
import {
  IPitchDesignContext,
  PitchDesignContext,
} from 'contexts/pitch-lists/pitch-design.context';
import { ISectionsContext, SectionsContext } from 'contexts/sections.context';
import {
  ITrainingContext,
  TrainingContext,
  TrainingProvider,
} from 'contexts/training.context';
import { IVideosContext, VideosContext } from 'contexts/videos/videos.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { CustomIconPath } from 'enums/custom.enums';
import { DropContainer } from 'enums/dnd.enums';
import { LOCAL_DATETIME_FORMAT_SHORT, LOCAL_TIMEZONE } from 'enums/env';
import { MachineButtonMode } from 'enums/machine.enums';
import { SectionName, SubSectionName } from 'enums/route.enums';
import { ACTIONS_KEY, TABLES } from 'enums/tables';
import { t } from 'i18next';
import { appearanceImgPath } from 'index';
import { TableIdentifier } from 'interfaces/cookies/i-app.cookie';
import { IMenuAction } from 'interfaces/i-menus';
import { IStatusColumn } from 'interfaces/i-pitch-list';
import { IQueueDefinition, QueueID } from 'interfaces/i-queue-mode';
import { ITableAction } from 'interfaces/tables/action';
import { ITableColumn } from 'interfaces/tables/columns';
import { ITableListener } from 'interfaces/tables/listener';
import { ITablePageable } from 'interfaces/tables/pagination';
import { ITableReorder } from 'interfaces/tables/reordering';
import { ITableSelectable } from 'interfaces/tables/selection';
import { ITableSortable } from 'interfaces/tables/sorting';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { BallHelper } from 'lib_ts/classes/ball.helper';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { getMSFromMSDict } from 'lib_ts/classes/ms.helper';
import { PlateHelper } from 'lib_ts/classes/plate.helper';
import { TrajHelper } from 'lib_ts/classes/trajectory.helper';
import { VideoHelper } from 'lib_ts/classes/video.helper';
import { UserRole } from 'lib_ts/enums/auth.enums';
import { ERROR_MSGS } from 'lib_ts/enums/errors.enums';
import { WsMsgType } from 'lib_ts/enums/machine-msg.enum';
import { TrainingMode } from 'lib_ts/enums/machine.enums';
import { MLB_OUTCOME_CODES } from 'lib_ts/enums/mlb-stats-api/guid-metadata-types.enum';
import {
  BuildPriority,
  PitchListExtType,
  SHUFFLE_FREQUENCY_OPTIONS,
} from 'lib_ts/enums/pitches.enums';
import { RADIX } from 'lib_ts/enums/radix-ui';
import {
  IFireResponseMsg,
  ITrainingMsg,
} from 'lib_ts/interfaces/i-machine-msg';
import { IPitch, IPitchList } from 'lib_ts/interfaces/pitches';
import React, { useContext } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import { MainService } from 'services/main.service';
import { SessionEventsService } from 'services/session-events.service';
import slugify from 'slugify';

const COMPONENT_NAME = 'PitchList';

// if true, training pitches that can be refreshed will refresh them without user confirmation
const AUTO_REFRESH_ON_TRAIN = true;

const ENABLE_LOGS = false;

// ms to wait after a remote command is received before actually sending mstarget to machine
const DELAY_REMOTE_SEND_MS = 2_000;

// group name starts with _ so that it renders as a separator in the menu
enum ActionGroup {
  Primary = '_1',
  Secondary = '_2',
  Tertiary = '_3',
}

const REPEAT_ONE: IQueueDefinition = {
  id: QueueID.RepeatOne,
  label: 'Repeat One',
  tooltip: 'Repeat a pitch until a new one is selected.',
  icon: <CustomIcon icon={CustomIconPath.RepeatOne} />,
};

const REPEAT_ALL: IQueueDefinition = {
  id: QueueID.RepeatAll,
  label: 'Repeat All',
  tooltip: 'Repeat all pitches in list according to the current sequence.',
  icon: <LoopIcon />,
};

const SHUFFLE_EACH: IQueueDefinition = {
  id: QueueID.ShuffleEach,
  label: 'Shuffle',
  tooltip: 'Select a random next pitch after each fire.',
  icon: <ShuffleIcon />,
};

const Q_DEFINITIONS: IQueueDefinition[] = [
  REPEAT_ALL,
  REPEAT_ONE,
  SHUFFLE_EACH,
];

interface IQueueOptions {
  add: IPitch;
  remove: IPitch;
}

const IDENTIFIER = TableIdentifier.PitchList;

const Q_SORT_KEY = '_queue_sort';

const DEFAULT_QUEUE_DEF = REPEAT_ONE;

const PAGE_SIZES = TABLES.PAGE_SIZES.XL;

const MACHINE_BTN_CLASSES = 'width-200px text-titlecase';

interface IBaseProps {
  globalCx: IGlobalContext;
  cookiesCx: ICookiesContext;
  authCx: IAuthContext;
  hittersCx: IHittersContext;
  listsCx: IPitchListsContext;
  listCx: IPitchListContext;
  machineCx: IMachineContext;
  matchingCx: IMatchingShotsContext;
  designCx: IPitchDesignContext;
  sectionsCx: ISectionsContext;
  videosCx: IVideosContext;
  aimingCx: IAimingContext;
}

interface IProps extends IBaseProps {
  tableCx: ITableContext;
}

export interface IQueueState {
  /** ids of pitches from active list, in order set by queue mode */
  queuePitchIDs: string[];
  queueDef: IQueueDefinition;
}

interface IDialogs {
  // confirm
  dialogDeleteList?: number;
  dialogDeletePitches?: number;
  dialogResetList?: number;

  // list
  dialogCopyList?: number;
  dialogEditList?: number;
  dialogCard?: number;
  dialogRenameFolder?: number;

  // selected pitch
  dialogData?: number;
  dialogEdit?: number;
  dialogEditBreaks?: number;
  dialogEditSpins?: number;
  dialogOptimize?: number;

  // manage pitches
  dialogChangeVideo?: number;
  dialogCopy?: number;
  dialogReset?: number;
  dialogTraining?: number;

  // misc
  dialogSearch?: number;
}

interface ISearch {
  // allows typing without triggering a search until button is clicked
  searchName?: string;
  searchKey: number;
}

interface IState extends IQueueState, IDialogs, ISearch {
  managePitches?: IPitch[];

  /** attaches to fire events (e.g. rehab session, plate discipline, etc...) */
  tags: string;
  /** for feedback to user without resorting to toasts */
  logs: ILog[];

  ignoreAutoFire: boolean;
}

const MIN_SHUFFLE_FREQUENCY = parseInt(SHUFFLE_FREQUENCY_OPTIONS[0].value);

export const PitchListHoc = () => {
  const props: IBaseProps = {
    globalCx: useContext(GlobalContext),
    cookiesCx: useContext(CookiesContext),
    authCx: useContext(AuthContext),
    hittersCx: useContext(HittersContext),
    listsCx: useContext(PitchListsContext),
    listCx: useContext(PitchListContext),
    machineCx: useContext(MachineContext),
    matchingCx: useContext(MatchingShotsContext),
    designCx: useContext(PitchDesignContext),
    sectionsCx: useContext(SectionsContext),
    videosCx: useContext(VideosContext),
    aimingCx: useContext(AimingContext),
  };

  // this seems janky but TS kept throwing errors otherwise
  return (
    <TableProvider>
      <TableContext.Consumer>
        {(tableCx) => <PitchList {...props} tableCx={tableCx} />}
      </TableContext.Consumer>
    </TableProvider>
  );
};

class PitchList extends React.Component<IProps, IState> {
  /** for auto-advancing through the queue,
   * when set, next time the pitch with matching ID is set as selectedPitch,
   * automatically send to machine
   */
  private autoSendPitchID?: string;

  /** will be consumed (and reset to undefined) whenever a pitch preview msg is sent */
  private changedFromRemote?: boolean;

  /**
   * when set, a timeout will be created to avoid spamming mstarget messages
   */
  private delaySendTargetMS?: number;

  private sendTimeout?: NodeJS.Timeout;
  private refreshTimeout?: NodeJS.Timeout;

  private fileInput?: CommonSimpleFileUploader;
  private fireButton?: MachineFireButton;

  private readonly BASE_COLUMNS: ITableColumn[] = [
    {
      label: '#',
      key: Q_SORT_KEY,
      align: 'center',
      thClassNameFn: () => 'width-40px',
      classNameFn: () => 'width-40px',
      sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
        const aIndex = this.state.queuePitchIDs.findIndex((id) => id === a._id);
        const bIndex = this.state.queuePitchIDs.findIndex((id) => id === b._id);

        if (aIndex === -1 && bIndex === -1) {
          /** neither are in queue, don't change order */
          return 0;
        }

        if (aIndex === -1) {
          /** only b is in queue, put a after */
          return 1;
        }

        if (bIndex === -1) {
          /** only a is in queue, put a first */
          return -1;
        }

        /** both are in queue, sort by order */
        return (aIndex > bIndex ? -1 : 1) * dir;
      },
      formatFn: (pitch: IPitch) => {
        const showSpinner =
          this.props.machineCx.lastPitchID === pitch._id &&
          this.fireButton?.getFiring();

        if (showSpinner) {
          return <Spinner />;
        }

        const index =
          this.state.queueDef.id === QueueID.RepeatAll &&
          this.props.aimingCx.pitch?._id !== pitch._id
            ? this.state.queuePitchIDs.findIndex((id) => id === pitch._id) + 1
            : undefined;

        if (index) {
          return index;
        }

        const showCaret = this.props.aimingCx.pitch?._id === pitch._id;

        return (
          <CaretRightIcon
            style={{
              marginTop: RADIX.ICON.TABLE_MT,
              // draw an invisible caret so that the column width doesn't shift after first selection
              opacity: showCaret ? 1 : 0,
            }}
          />
        );
      },
    },
    {
      label: 'common.actions',
      key: ACTIONS_KEY,
      actions: this.getActions(),
    },
    {
      label: 'pl.status',
      key: '_status',
      align: 'center',
      thClassNameFn: () => 'width-80px',
      tooltipFn: (pitch: IPitch) => {
        const status = this.getStatusColumn(pitch);

        const lines: string[] = [`${t('pl.status')}: ${status.text}`];

        const ms = getMSFromMSDict(pitch, this.props.machineCx.machine).ms;
        if (ms) {
          lines.push(
            `${t('common.model')}: ${this.props.machineCx.getModelName(
              ms.model_id
            )}`
          );
        }

        if (ms?.last_built) {
          lines.push(
            `${t('common.updated')}: ${format(
              parseISO(ms.last_built),
              LOCAL_DATETIME_FORMAT_SHORT,
              { timeZone: LOCAL_TIMEZONE }
            )}`
          );
        }

        return lines.join('\n\n');
      },
      sortRowsFn: (a: IPitch, b: IPitch, dir: number) => {
        const aCol = this.getStatusColumn(a);
        const bCol = this.getStatusColumn(b);

        return dir * (aCol.sortValue > bCol.sortValue ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        const summary = this.props.matchingCx.getAggShotsByPitch(pitch);
        const status = this.getStatusColumn(pitch);
        const canRefresh = this.props.matchingCx.readyToRefresh(pitch);

        return (
          <Box
            pt="1"
            data-testid="ShotSummary"
            data-total={summary?.total ?? 0}
            data-qt={summary?.qt ?? 0}
            data-qt-complete={summary?.qt_complete ?? false}
          >
            {!AUTO_REFRESH_ON_TRAIN && canRefresh ? (
              <UpdateIcon />
            ) : (
              status.icon
            )}
          </Box>
        );
      },
    },
    {
      label: 'common.pitch',
      key: 'name',
      formatFn: (pitch: IPitch) => {
        return this.getPitchColumnText(pitch);
      },
      tooltipFn: (pitch: IPitch) => {
        const items: { label: string; value?: string }[] = [
          { label: 'common.name', value: pitch.name },
          { label: 'common.year', value: pitch.year },
          { label: 'common.game', value: pitch.game },
          { label: 'common.hitter', value: pitch.hitter },
          {
            label: 'common.outcome',
            value:
              MLB_OUTCOME_CODES.find((o) => o.code === pitch.outcome)
                ?.description ?? pitch.outcome,
          },
        ].filter((i) => i.value);

        if (items.length === 0) {
          return;
        }

        return items.map((l) => `${t(l.label)}: ${l.value}`).join('\n\n');
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const textA = this.getPitchColumnText(pitchA);
        const textB = this.getPitchColumnText(pitchB);
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'common.priority',
      key: 'priority',
      formatFn: (pitch: IPitch) =>
        t(
          pitch.priority === BuildPriority.Breaks
            ? 'common.break'
            : 'common.spin'
        ),
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const textA = pitchA.priority ?? BuildPriority.Spins;
        const textB = pitchB.priority ?? BuildPriority.Spins;
        return -dir * textA.localeCompare(textB);
      },
    },
    {
      label: 'common.type',
      key: 'type',
    },
    {
      label: 'common.hand',
      key: 'hand',
      align: 'center',
      formatFn: (pitch: IPitch) => {
        const isRight = Math.sign(pitch.bs.px) <= 0;
        return (
          <Badge color={isRight ? RADIX.COLOR.RIGHT : RADIX.COLOR.LEFT}>
            {t(isRight ? 'common.rhp' : 'common.lhp')}
          </Badge>
        );
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        return (
          dir * (Math.sign(pitchA.bs.px) < Math.sign(pitchB.bs.px) ? 1 : -1)
        );
      },
    },
    {
      label: 'common.zone',
      key: '_zone',
      align: 'center',
      formatFn: (pitch: IPitch) => {
        const summary = PlateHelper.getPitchSummary(pitch);
        return (
          <img
            className="StrikeZoneIcon"
            width={24}
            height={24}
            src={appearanceImgPath(`plate/${summary.grid}.svg`)}
          />
        );
      },
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const sortA = PlateHelper.getPitchSummary(pitchA).sort;
        const sortB = PlateHelper.getPitchSummary(pitchB).sort;
        return -dir * (sortA < sortB ? -1 : 1);
      },
    },
    {
      label: 'common.speed',
      key: 'speed',
      subLabel: 'mph',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.bs.vnet ?? BallHelper.getSpeed(pitchA.traj);
        const vb = pitchB.bs.vnet ?? BallHelper.getSpeed(pitchB.traj);
        return dir * (va < vb ? 1 : -1);
      },
      formatFn: (pitch: IPitch) => {
        return TrajHelper.getSpeedMPH(pitch.traj)?.toFixed(1);
      },
    },
    {
      label: 'common.spin',
      key: 'spin',
      subLabel: 'rpm',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.bs.wnet ?? BallHelper.getNetSpin(pitchA.bs);
        const vb = pitchB.bs.wnet ?? BallHelper.getNetSpin(pitchB.bs);
        return dir * (va < vb ? 1 : -1);
      },
      formatFn: (pitch: IPitch) => {
        return (pitch.bs.wnet ?? BallHelper.getNetSpin(pitch.bs)).toFixed(0);
      },
    },
    {
      label: 'common.h-break',
      key: 'breaks_xInches',
      subLabel: 'in',
      align: 'right',
      tooltipFn: () => PitchDesignHelper.HB_TOOLTIP_TEXT,
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        // don't change order
        if (
          pitchA.priority !== BuildPriority.Breaks &&
          pitchB.priority !== BuildPriority.Breaks
        ) {
          return 0;
        }

        // puts all non-breaks stuff after breaks stuff, regardless of direction
        if (pitchA.priority !== BuildPriority.Breaks) {
          return 1;
        }

        if (pitchB.priority !== BuildPriority.Breaks) {
          return -1;
        }

        const va = PitchListHelper.getSafePitchBreaks(pitchA)?.xInches;
        const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.xInches;

        // don't change order
        if (va === undefined && vb === undefined) {
          return 0;
        }

        if (va === undefined) {
          return 1;
        }

        if (vb === undefined) {
          return -1;
        }

        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        if (pitch.priority !== BuildPriority.Breaks) {
          return;
        }

        const value = PitchListHelper.getSafePitchBreaks(pitch)?.xInches;

        if (value === undefined) {
          return;
        }

        return (-1 * value).toFixed(1);
      },
    },
    {
      label: 'common.v-break',
      key: 'breaks_zInches',
      subLabel: 'in',
      align: 'right',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        // don't change order
        if (
          pitchA.priority !== BuildPriority.Breaks &&
          pitchB.priority !== BuildPriority.Breaks
        ) {
          return 0;
        }

        // puts all non-breaks stuff after breaks stuff, regardless of direction
        if (pitchA.priority !== BuildPriority.Breaks) {
          return 1;
        }

        if (pitchB.priority !== BuildPriority.Breaks) {
          return -1;
        }

        const va = PitchListHelper.getSafePitchBreaks(pitchA)?.zInches;
        const vb = PitchListHelper.getSafePitchBreaks(pitchB)?.zInches;

        // don't change order
        if (va === undefined && vb === undefined) {
          return 0;
        }

        if (va === undefined) {
          return 1;
        }

        if (vb === undefined) {
          return -1;
        }

        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => {
        if (pitch.priority !== BuildPriority.Breaks) {
          return;
        }

        const value = PitchListHelper.getSafePitchBreaks(pitch)?.zInches;

        if (value === undefined) {
          return;
        }

        return value.toFixed(1);
      },
    },
    {
      label: 'common.frequency',
      key: 'frequency',
      sortRowsFn: (pitchA: IPitch, pitchB: IPitch, dir: number) => {
        const va = pitchA.frequency ?? MIN_SHUFFLE_FREQUENCY;
        const vb = pitchB.frequency ?? MIN_SHUFFLE_FREQUENCY;
        return dir * (va > vb ? -1 : 1);
      },
      formatFn: (pitch: IPitch) => (
        <CommonSelectInput
          key={`${pitch._id}-frequency`}
          id="PL-PitchFrequency"
          options={SHUFFLE_FREQUENCY_OPTIONS}
          value={(pitch.frequency ?? MIN_SHUFFLE_FREQUENCY).toString()}
          onOptionalNumericChange={(v) => {
            pitch.frequency = v;

            // silently should not update the array => should not trigger table to re-render
            this.props.listCx.updatePitches({
              payloads: [{ _id: pitch._id, frequency: v }],
              silently: true,
            });
          }}
          skipSort
        />
      ),
    },
  ];

  constructor(props: IProps) {
    super(props);

    this.state = {
      queueDef: DEFAULT_QUEUE_DEF,
      queuePitchIDs: [],

      tags: '',
      logs: [],

      ignoreAutoFire: true,

      searchName: props.listCx.searchCriteria.name,
      searchKey: Date.now(),
    };

    /**
     * machine and firing
     */
    this.afterTrainingMsg = this.afterTrainingMsg.bind(this);
    this.handleFireResponse = this.handleFireResponse.bind(this);
    this.handleTrainPitches = this.handleTrainPitches.bind(this);
    this.sendSelected = this.sendSelected.bind(this);
    this.setPitchResetLocation = this.setPitchResetLocation.bind(this);

    /**
     * table
     */
    this.changeActivePitch = this.changeActivePitch.bind(this);
    this.handleDragPitchToList = this.handleDragPitchToList.bind(this);
    this.afterChangeSelected = this.afterChangeSelected.bind(this);

    /** queue stuff */
    this.getNextPitch = this.getNextPitch.bind(this);
    this.getQueueIDs = this.getQueueIDs.bind(this);
    this.changeQueue = this.changeQueue.bind(this);
    this.getListActions = this.getListActions.bind(this);

    /** on-update processors */
    this.onChangeTrained = this.onChangeTrained.bind(this);

    /** misc */
    this.anyLoading = this.anyLoading.bind(this);
    this.getActions = this.getActions.bind(this);
    this.getTrainingStatus = this.getTrainingStatus.bind(this);
    this.getPitchColumnText = this.getPitchColumnText.bind(this);
    this.getStatusColumn = this.getStatusColumn.bind(this);
    this.localMachineButtonMode = this.localMachineButtonMode.bind(this);
    this.onEndTraining = this.onEndTraining.bind(this);
    this.performSearch = this.performSearch.bind(this);

    /** renderers */
    this.renderBody = this.renderBody.bind(this);
    this.renderDialogs = this.renderDialogs.bind(this);
    this.renderFireTagsControls = this.renderFireTagsControls.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
    this.renderSidebar = this.renderSidebar.bind(this);
    this.renderTableFooter = this.renderTableFooter.bind(this);
    this.renderToolbar = this.renderToolbar.bind(this);
    this.renderTrainingDialog = this.renderTrainingDialog.bind(this);
  }

  componentDidMount() {
    WebSocketHelper.on(WsMsgType.M2U_FireResponse, this.handleFireResponse);
  }

  componentWillUnmount() {
    WebSocketHelper.remove(WsMsgType.M2U_FireResponse, this.handleFireResponse);

    this.props.machineCx.setAutoFire(false);

    clearTimeout(this.sendTimeout);
    clearTimeout(this.refreshTimeout);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    if (
      this.props.authCx.restrictedGameStatus() &&
      this.state.dialogTraining !== undefined
    ) {
      // auto-end training (if necessary) upon start of game
      NotifyHelper.warning({
        message_md: `Training is not allowed during home games.`,
      });

      this.setState(
        {
          dialogTraining: undefined,
        },
        () => this.onEndTraining(this.state.managePitches)
      );
    }

    // this should only trigger at most once, after that will only work when forced
    if (!prevProps.matchingCx.aggReady && this.props.matchingCx.aggReady) {
      this.changeQueue(this.state.queueDef.id);
    }
  }

  private async onEndTraining(trained: IPitch[] | undefined) {
    if (!trained || trained.length === 0) {
      return;
    }

    this.props.machineCx.resetMSHash();

    // deselect any row that was selected to force retrieval of shots on click
    this.props.tableCx.setSelected({
      page: 0,
      index: -1,
    });

    // get shots for anything that was just trained
    this.props.matchingCx
      .updatePitches({
        pitches: trained,
        includeHitterPresent: false,
        includeLowConfidence: true,
      })
      .then(() => this.onChangeTrained());
  }

  private getPitchColumnText(pitch: IPitch): string {
    const parts: string[] = [];

    if (pitch.year) {
      parts.push(`[${pitch.year}]`);
    }

    parts.push(pitch.name || '(no name)');

    return parts.join(' ');
  }

  private getStatusColumn(pitch: IPitch): IStatusColumn {
    const shots = this.props.matchingCx.getAggShotsByPitch(pitch);

    const output: IStatusColumn = {
      sortValue: 0,
      trained: false,
      precision: false,
      text: 'Untrained',
      icon: (
        <Text color={RADIX.COLOR.NEUTRAL}>
          <CrossCircledIcon />
        </Text>
      ),
    };

    // whether the pitch is trained via QT or old fashioned way
    output.trained = !!shots?.trained;

    output.precision =
      !!shots?.trained &&
      !!getMSFromMSDict(pitch, this.props.machineCx.machine).ms
        ?.precision_trained;

    if (output.precision) {
      output.sortValue = 2;
      output.text = 'Precision Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CustomIcon icon={CustomIconPath.CheckCircledFilled} />
        </Text>
      );
    } else if (output.trained) {
      output.sortValue = 1;
      output.text = 'Trained';
      output.icon = (
        <Text color={RADIX.COLOR.SUCCESS}>
          <CheckCircledIcon />
        </Text>
      );
    }

    return output;
  }

  /**
   * true if any of lists context, machine context, matching context, or model refresh are loading
   */
  private anyLoading(): boolean {
    return (
      this.props.listCx.loading ||
      this.props.listsCx.loading ||
      this.props.machineCx.loading ||
      this.props.matchingCx.loading
    );
  }

  /** the server will emit trainingresponse even if not in training, e.g. for continuous data collection */
  private afterTrainingMsg(data: ITrainingMsg) {
    if (this.state.dialogTraining) {
      // training will handle the msgs while open
      return;
    }

    if (data.success === undefined) {
      return;
    }

    if (!data.success) {
      return;
    }

    /** updates the shots # in the table when necessary */
    this.props.aimingCx.updateMatches();
  }

  private setPitchResetLocation(pitch: IPitch) {
    this.props.aimingCx.setPlate(
      pitch.plate_loc_backup ?? TrajHelper.getPlateLoc(pitch.traj)
    );

    return this.props.aimingCx.setPitch(pitch);
  }

  /** adds extra metadata to target before sending to machine */
  private sendSelected(trigger: string, auto: boolean) {
    if (this.props.globalCx.dialogs.length > 0) {
      return;
    }

    if (auto && !this.props.machineCx.checkActive(true)) {
      // silently skip auto-sending when not connected
      return;
    }

    const pitch = this.props.aimingCx.pitch;
    if (!pitch) {
      return;
    }

    if (auto && (!this.autoSendPitchID || this.autoSendPitchID !== pitch._id)) {
      // skip auto-sending when not necessary
      return;
    }

    /** ensure we don't auto-send more than once, e.g. if user re-selects the active row */
    if (this.autoSendPitchID === pitch._id) {
      this.autoSendPitchID = undefined;
    }

    const getQueuePitchByIndex = (
      index: number,
      delta: number
    ): IPitch | undefined => {
      if (!this.changedFromRemote) {
        // only show previous/next when changing from remote
        return;
      }

      if (this.state.queueDef.id === QueueID.ShuffleEach) {
        // shuffle should never indicate what comes next/previous because it's random
        return;
      }

      const modulus = this.state.queuePitchIDs.length;
      const safeIndex = (index + delta + modulus) % modulus;
      const id = this.state.queuePitchIDs[safeIndex];
      return this.props.listCx.pitches.find((p) => p._id === id);
    };

    const index = this.state.queuePitchIDs.findIndex((id) => id === pitch._id);

    this.props.machineCx.sendPitchPreview({
      trigger: COMPONENT_NAME,
      current: pitch,
      prev: getQueuePitchByIndex(index, -1),
      next: getQueuePitchByIndex(index, 1),
    });

    this.changedFromRemote = undefined;

    this.fireButton?.setAwaitingResend(true);

    const sendCallback = () => {
      this.props.aimingCx.sendToMachine({
        training: false,
        skipPreview: true,
        trigger: trigger,
        list: this.props.listsCx.lists.find((l) => l._id === pitch._parent_id),
        hitter: this.props.hittersCx.active,
        onSuccess: () => {
          this.fireButton?.setAwaitingResend(false);

          // reset the delay after sending, to avoid impacting non-delayed sends in the future
          this.delaySendTargetMS = undefined;
        },
      });
    };

    if (this.delaySendTargetMS === undefined || this.delaySendTargetMS <= 0) {
      sendCallback();
      return;
    }

    clearTimeout(this.sendTimeout);
    this.sendTimeout = setTimeout(sendCallback, this.delaySendTargetMS);
  }

  /**
   * tells table to update selection and send to machine, to be triggered by fire response
   * @param delta indicates how many pitches to move forwards (+) or backwards (-) in queue
   * @param delay_ms tells the app to wait before sending the next pitch
   * @param cascadeSend tells the app to send whatever is selected next
   */
  private async changeActivePitch(config: {
    delta: number;
    delay_ms?: number;
    cascadeSend?: boolean;
  }) {
    if (this.props.globalCx.dialogs.length > 0) {
      return;
    }

    const nextPitch = this.getNextPitch(config.delta);
    if (!nextPitch) {
      return;
    }

    if (config.delay_ms !== undefined && config.delay_ms > 0) {
      this.delaySendTargetMS = config.delay_ms;
    }

    /** make a note to send the pitch when it's next selected */
    this.autoSendPitchID = nextPitch._id;
    this.changedFromRemote = config.cascadeSend;

    await this.setPitchResetLocation(nextPitch);

    const c = this.props.tableCx.lookupCoordinates('_id', nextPitch._id);
    this.props.tableCx.setSelected(c);

    // afterChangeSelected would trigger after this
  }

  private getNextPitch(delta: number): IPitch | undefined {
    if (this.state.queueDef.id === QueueID.ShuffleEach) {
      const USE_BUCKET_LOTTERY = true;

      if (USE_BUCKET_LOTTERY) {
        // this algo picks a frequency bucket first and then picks a matching pitch from the bucket
        // e.g. with frequencies 1, 2, and 3 in use, the pool of values will be [1, 2, 2, 3, 3, 3]
        // it will pick a random value from this pool (the bucket), and then find all pitches with matching frequency value (items in the bucket)
        // then it will pick a random pitch from the matches
        // this ensures that have many pitches of the same frequency doesn't dilute all other frequencies
        const qPitches = this.state.queuePitchIDs
          .map((id) => this.props.listCx.pitches.find((p) => p._id === id))
          .filter((p) => p) as IPitch[];

        // get unique frequencies that occur in the queue
        const qFreqs: number[] = ArrayHelper.unique(
          qPitches.map((p) => p.frequency ?? MIN_SHUFFLE_FREQUENCY)
        );

        // weighted freq => higher numbers will show up more times
        const wFreqs: number[] = qFreqs.flatMap((f) => {
          const o: number[] = [];

          // e.g. 3 => 3 will show up 3 times
          for (let i = 0; i < f; i++) {
            o.push(f);
          }

          return o;
        });

        // pick one of the freq randomly
        const rFreq = wFreqs[Math.round(Math.random() * 1_000) % wFreqs.length];

        // get all pitches from the queue that have frequency === rFreq
        const freqPitches = qPitches.filter((p) => {
          const safeFreq = p.frequency ?? MIN_SHUFFLE_FREQUENCY;
          return safeFreq === rFreq;
        });

        const iNext =
          Math.round(Math.random() * 1_000_000) % freqPitches.length;
        return freqPitches[iNext];
      }

      // this algo enters each pitch into a raffle based on the frequency number and picks a random pitch from the raffle
      // e.g. a pitch with frequency 2 will be entered into the raffle 2x, whereas a pitch with frequency 1 will be entered 1x
      // a large number of pitches of a single frequency will tend to dilute the odds of others
      // 97 pitches of frequency 1 and 1 pitch of frequency 3 => the pitch with frequency 3 will only have a 3% or 3/100 (i.e. 97x1 + 1x3) chance of being selected
      // despite it being "high" frequency and everything else is "low"
      const weightedIDs: string[] = [];

      this.state.queuePitchIDs.forEach((id) => {
        const pitch = this.props.listCx.pitches.find((p) => p._id === id);

        if (!pitch) {
          return;
        }

        // undefined => 1
        const safeFreq = pitch.frequency ?? MIN_SHUFFLE_FREQUENCY;

        // higher frequency => more occurrences of the pitch in the list
        for (let i = 0; i < safeFreq; i++) {
          weightedIDs.push(pitch._id);
        }
      });

      const iNext = Math.round(Math.random() * 1_000_000) % weightedIDs.length;
      const nextID = weightedIDs[iNext];
      return this.props.listCx.pitches.find((p) => p._id === nextID);
    }

    const length = this.state.queuePitchIDs.length;
    if (length === 0) {
      return;
    }

    /** defaults to 0 if nothing was sent (e.g. user hits next/previous on remote before sending any pitch) */
    const current = this.props.tableCx.selectedData as IPitch | undefined;

    const iCurrent = this.state.queuePitchIDs.findIndex(
      (id) => id === current?._id
    );

    /** adding length allows the function to work in reverse and loop around */
    const iNext = (iCurrent + length + delta) % length;
    const nextID = this.state.queuePitchIDs[iNext];
    return this.props.listCx.pitches.find((p) => p._id === nextID);
  }

  private handleFireResponse(event: CustomEvent) {
    if (this.props.globalCx.dialogs.length > 0) {
      return;
    }

    const data: IFireResponseMsg = event.detail;

    if (!data) {
      NotifyHelper.error({
        message_md: `Empty fire response payload. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
      return;
    }

    if (!data.status) {
      const message = data.message ?? 'Failed to fire, reason unknown';
      NotifyHelper.error({ message_md: message, inbox: true });
      return;
    }

    if (this.state.queueDef.id === QueueID.RepeatOne) {
      // don't auto-change pitch
      return;
    }

    this.changeActivePitch({
      delta: 1,
      cascadeSend: true,
    });
  }

  private async handleTrainPitches(config: {
    ids: string[];
    promptRefresh: boolean;
  }) {
    // always use pitches from the context in case they were (auto-)refreshed and differ from what's in selectedPitch or managePitches
    const pitches = this.props.listCx.pitches.filter((p) =>
      config.ids.includes(p._id)
    );

    if (pitches.length === 0) {
      NotifyHelper.error({
        message_md: 'There are no pitches to train.',
      });
      return;
    }

    await this.props.matchingCx.updatePitches({
      pitches: pitches,
      includeHitterPresent: false,
      includeLowConfidence: true,
    });

    const canRefresh = pitches.filter((p) =>
      this.props.matchingCx.readyToRefresh(p)
    );

    // by checking promptRefresh here, we guarantee we never loop indefinitely (e.g. if model refresh fails)
    if (canRefresh.length > 0 && config.promptRefresh) {
      const rebuildCallback = async () => {
        await this.props.listCx.rebuild(canRefresh.map((p) => p._id));

        clearTimeout(this.refreshTimeout);

        this.refreshTimeout = setTimeout(() => {
          if (!this.props.matchingCx.readyToTrain()) {
            NotifyHelper.warning({
              message_md:
                'Training cannot be performed at this time. Please try again later.',
            });
            return;
          }

          this.handleTrainPitches({ ...config, promptRefresh: false });
        }, 1_000);
      };

      if (AUTO_REFRESH_ON_TRAIN) {
        rebuildCallback();
        return;
      }

      const single = canRefresh.length === 1;

      NotifyHelper.warning({
        message_md: `${
          single ? 'One pitch' : `${canRefresh.length} pitches`
        } can be refreshed with your latest model. Refreshing before training is **strongly** recommended for best results.`,
        delay_ms: 0,
        buttons: [
          {
            label: 'Refresh & Train',
            color: RADIX.COLOR.TRAIN_PITCH,
            onClick: rebuildCallback,
            dismissAfterClick: true,
          },
          {
            label: 'Ignore',
            onClick: () => {
              if (!this.props.matchingCx.readyToTrain()) {
                NotifyHelper.warning({
                  message_md:
                    'Training cannot be performed at this time. Please try again later.',
                });
                return;
              }

              this.handleTrainPitches({
                ...config,
                promptRefresh: false,
              });
            },
            dismissAfterClick: true,
          },
        ],
      });
      return;
    }

    this.setState({
      dialogTraining: Date.now(),
      managePitches: pitches,
    });
  }

  /** for active row selection functionality */
  private async afterChangeSelected(pitch: IPitch | undefined) {
    if (!pitch) {
      return;
    }

    await this.setPitchResetLocation(pitch);

    if (pitch && pitch._id === this.autoSendPitchID) {
      this.sendSelected('afterChangeSelected', true);
    }
  }

  // returns the IDs of pitches that can be queued
  private getQueueIDs(options?: Partial<IQueueOptions>): string[] {
    const trained = this.props.listCx.pitches.filter((p) =>
      this.props.matchingCx.isPitchTrained(p)
    );

    if (!options && trained.filter((p) => p._checked).length === 0) {
      // nothing is checked => queue is everything that is trained
      return trained.map((p) => p._id);
    }

    // at least one pitch is checked => queue should only contain trained AND checked pitches
    const trainedAndChecked = trained
      .filter((p) => p._checked)
      .map((p) => p._id);

    const nextQueue = this.state.queuePitchIDs.filter((id) => {
      const pitch = this.props.listCx.pitches.find((p) => p._id === id);

      if (!pitch) {
        // cannot find the pitch in the active list
        return false;
      }

      if (!pitch._checked) {
        // exclude previously queued pitch because it's not checked
        return false;
      }

      if (pitch._id === options?.remove?._id) {
        // apply remove option
        return false;
      }

      // anything that will continue to be queued, leave intact
      return trainedAndChecked.includes(id);
    });

    // add new items to the end (e.g. something became trained or checked)
    trainedAndChecked.forEach((id) => {
      if (!nextQueue.includes(id)) {
        nextQueue.push(id);
      }
    });

    if (options?.add && !nextQueue.includes(options.add._id)) {
      // apply add option
      nextQueue.push(options.add._id);
    }

    return nextQueue;
  }

  private async changeQueue(type: QueueID, options?: Partial<IQueueOptions>) {
    this.setState({
      queueDef: Q_DEFINITIONS.find((m) => m.id === type) ?? DEFAULT_QUEUE_DEF,
      queuePitchIDs: this.getQueueIDs(options),
    });
  }

  private renderTableFooter() {
    const pitch = this.props.aimingCx.pitch;
    const mode = this.localMachineButtonMode();
    const safeTags = `pitch-list,${this.state.tags}`;

    return (
      <Flex gap={RADIX.FLEX.GAP.SM} justify="end">
        <Box>{this.renderQueueToggle()}</Box>

        {this.props.authCx.current.role === UserRole.admin &&
          this.props.machineCx.getAutoFireButton({
            beforeToggleFn: () => this.setState({ ignoreAutoFire: true }),
          })}

        {this.renderMachineButton(mode, pitch)}

        {pitch && (
          <div hidden={mode !== MachineButtonMode.Fire}>
            <MachineFireButton
              ref={(elem) => (this.fireButton = elem as MachineFireButton)}
              className={MACHINE_BTN_CLASSES}
              cookiesCx={this.props.cookiesCx}
              machineCx={this.props.machineCx}
              aimingCx={this.props.aimingCx}
              hitter={this.props.hittersCx.active}
              tags={safeTags}
              beforeFire={() => this.setState({ ignoreAutoFire: false })}
              ignoreAutoFire={this.state.ignoreAutoFire}
              onReady={() => {
                if (
                  !this.props.machineCx.autoFire ||
                  this.state.ignoreAutoFire
                ) {
                  this.fireButton?.goToReady();
                  return;
                }

                if (this.props.globalCx.dialogs.length > 0) {
                  // never auto-fire while a dialog is open
                  return;
                }

                if (mode !== MachineButtonMode.Fire) {
                  // never auto-fire while not in firing mode
                  return;
                }

                this.fireButton?.performFire('auto', 'pitch list controls');
              }}
              firing
            />
          </div>
        )}
      </Flex>
    );
  }

  private renderMachineButton(mode: MachineButtonMode, pitch?: IPitch) {
    switch (mode) {
      case MachineButtonMode.Unavailable: {
        return <MachineUnavailableButton className={MACHINE_BTN_CLASSES} />;
      }

      case MachineButtonMode.Calibrate: {
        return <MachineCalibrateButton className={MACHINE_BTN_CLASSES} />;
      }

      case MachineButtonMode.Train: {
        return (
          <Button
            className={MACHINE_BTN_CLASSES}
            color={RADIX.COLOR.TRAIN_PITCH}
            disabled={!this.props.matchingCx.readyToTrain()}
            onClick={() =>
              this.handleTrainPitches({
                ids: pitch ? [pitch._id] : [],
                promptRefresh: true,
              })
            }
          >
            {t('common.train-pitch')}
          </Button>
        );
      }

      case MachineButtonMode.Refresh: {
        if (!pitch) {
          return;
        }

        return (
          <Button
            color={RADIX.COLOR.WARNING}
            className={MACHINE_BTN_CLASSES}
            onClick={async () => {
              this.props.machineCx.resetMSHash();

              this.setState({
                managePitches: [pitch],
                dialogResetList: Date.now(),
              });
            }}
          >
            {t('pl.refresh-model')}
          </Button>
        );
      }

      case MachineButtonMode.Send: {
        return (
          <Button
            color={RADIX.COLOR.SEND_PITCH}
            className={MACHINE_BTN_CLASSES}
            disabled={this.props.matchingCx.loading}
            onClick={() => this.sendSelected('send button', false)}
          >
            {t('common.load-pitch')}
          </Button>
        );
      }

      case MachineButtonMode.Fire:
      default: {
        return;
      }
    }
  }

  private renderQueueToggle() {
    const queueReady = !this.props.matchingCx.aggReady;

    return (
      <CommonTooltip
        trigger={
          <Button
            data-testid="QueueMode"
            disabled={queueReady}
            variant="soft"
            onClick={() => {
              const i = Q_DEFINITIONS.findIndex(
                (o) => o.id === this.state.queueDef.id
              );

              const iNext = (i + 1) % Q_DEFINITIONS.length;
              this.changeQueue(Q_DEFINITIONS[iNext].id);
            }}
          >
            {queueReady ? <Spinner /> : this.state.queueDef.icon}
          </Button>
        }
        text={this.state.queueDef.tooltip}
      />
    );
  }

  private getListActions(): IMenuAction[] | undefined {
    if (this.props.sectionsCx.active.subsection === SubSectionName.Library) {
      return;
    }

    if (this.anyLoading()) {
      return;
    }

    const activeList = this.props.listCx.active as IPitchList;

    if (!activeList) {
      return;
    }

    const restricted = this.props.authCx.restrictedGameStatus();

    const countTrained = this.getTrainingStatus(true).length;

    const canRefresh = this.props.listCx.pitches.filter((p) =>
      this.props.matchingCx.readyToRefresh(p)
    );

    const readonly = this.props.listCx.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        group: '_1',
        label: 'common.edit-list',
        prefixIcon: <Pencil2Icon />,
        invisible: readonly || restricted,
        onClick: () =>
          this.setState({
            dialogEditList: Date.now(),
          }),
      },
      {
        group: '_1',
        label: 'common.duplicate-list',
        prefixIcon: <CopyIcon />,
        invisible: restricted,
        onClick: () =>
          this.setState({
            dialogCopyList: Date.now(),
          }),
      },
      {
        group: '_1',
        label: 'common.reorder-list',
        prefixIcon: <RowsIcon />,
        invisible: readonly || restricted,
        onClick: () => {
          /** reset queue before reorder to avoid confusion after reorder list is complete */
          this.setState({ queuePitchIDs: [] });
          this.props.tableCx.showReorder();
        },
      },

      // divider

      {
        group: '_2',
        prefixIcon: <BaseballIcon />,
        label: 'common.create-pitch',
        disabled: this.props.authCx.restrictedGameStatus(),
        onClick: () => {
          // provide _parent_id to pre-select this list for save dialog
          const folderPitch = this.props.machineCx.getDefaultPitch();
          folderPitch._parent_id = activeList._id;

          this.props.designCx.setReference(folderPitch);

          this.props.sectionsCx.tryChangeSection({
            trigger: 'PitchesHeader > actions menu',
            section: SectionName.Pitches,
            subsection: SubSectionName.Design,
          });
        },
      },
      {
        group: '_2',
        prefixIcon: <UploadIcon />,
        label: 'common.upload-pitches',
        disabled: this.props.authCx.restrictedGameStatus(),
        onClick: () =>
          this.props.sectionsCx.tryChangeSection({
            trigger: 'PitchesHeader > actions menu',
            section: SectionName.Pitches,
            subsection: SubSectionName.Upload,
          }),
      },

      // divider

      {
        group: 'pl.bulk-edit',
        label: 'common.export-csv',
        prefixIcon: <DownloadIcon />,
        invisible:
          readonly || restricted || this.props.listCx.pitches.length === 0,
        onClick: () => {
          const pitches = this.props.listCx.pitches.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: this.props.machineCx.machine.plate_distance,
              video: this.props.videosCx.videos.find(
                (v) => v._id === p.video_id
              ),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (all) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: pitches.length,
            },
          });

          MainService.getInstance()
            .convertJSONToCSV(pitches)
            .then((csvString) => {
              const blob = new Blob([csvString], { type: 'text/csv' });
              MiscHelper.saveAs(blob, `${slugify(activeList.name)}.csv`);
            });
        },
      },
      {
        group: 'pl.bulk-edit',
        label: 'common.import-csv',
        prefixIcon: <UploadIcon />,
        invisible:
          readonly || restricted || this.props.listCx.pitches.length === 0,
        onClick: () => {
          if (this.fileInput) {
            this.fileInput.handleClick();
          } else {
            console.warn('no file input element');
          }
        },
      },

      // divider

      {
        group: '_3',
        label: 'pl.refresh-models',
        prefixIcon: <ReloadIcon />,
        invisible: ONLY_ALLOW_REFRESH_ON_TRAIN || canRefresh.length === 0,
        color: RADIX.COLOR.SUCCESS,
        onClick: () =>
          this.setState({
            dialogResetList: Date.now(),
            managePitches: canRefresh,
          }),
      },
      {
        group: '_3',
        label: 'common.reset-training-data',
        prefixIcon: <ResetIcon />,
        invisible: readonly || countTrained === 0,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          this.setState({
            dialogReset: Date.now(),
            managePitches: this.props.listCx.pitches,
          }),
      },
      {
        group: '_3',
        label: 'pl.rename-folder',
        prefixIcon: <InputIcon />,
        invisible: readonly || restricted || !activeList.folder,
        color: RADIX.COLOR.WARNING,
        onClick: () =>
          this.setState({
            dialogRenameFolder: Date.now(),
          }),
      },
      {
        group: '_3',
        label: 'pl.manage-card',
        prefixIcon: <SuperAdminIcon />,
        invisible:
          this.props.authCx.current.role !== UserRole.admin ||
          activeList.type !== PitchListExtType.Card,
        color: RADIX.COLOR.SUPER_ADMIN,
        onClick: () =>
          this.setState({
            dialogCard: Date.now(),
          }),
      },
      {
        group: '_3',
        label: 'common.delete',
        prefixIcon: <TrashIcon />,
        invisible: readonly || restricted,
        color: RADIX.COLOR.DANGER,
        onClick: () =>
          this.setState({
            dialogDeleteList: Date.now(),
          }),
      },
    ];

    return actions;
  }

  private getCheckedMenuActions(): IMenuAction[] {
    const selected = this.props.listCx.pitches.filter((p) => p._checked);
    if (selected.length === 0) {
      return [];
    }

    const restricted = this.props.authCx.restrictedGameStatus();

    const countTrained = this.getTrainingStatus(true).filter(
      (p) => p._checked
    ).length;

    const canRefresh = selected.filter((p) =>
      this.props.matchingCx.readyToRefresh(p)
    );

    const readonly = this.props.listCx.activeReadOnly();

    const actions: IMenuAction[] = [
      {
        label: 'common.train-pitches',
        invisible: !this.props.matchingCx.readyToTrain(),
        color: RADIX.COLOR.TRAIN_PITCH,
        onClick: () =>
          this.handleTrainPitches({
            ids: selected.map((p) => p._id),
            promptRefresh: true,
          }),
      },
      {
        label: 'pl.change-videos',
        invisible: readonly,
        onClick: () =>
          this.setState({
            dialogChangeVideo: Date.now(),
            managePitches: selected,
          }),
      },
      {
        label: 'pl.copy-pitches',
        invisible: restricted,
        onClick: () => {
          const checkedPitchIDs = selected.map((p) => p._id);
          const originalPitches = this.props.listCx.pitches.filter((p) =>
            checkedPitchIDs.includes(p._id)
          );

          if (originalPitches.length !== checkedPitchIDs.length) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find all checked pitches in active context.`,
              checkedPitches: selected,
              originalPitches,
            });
            return;
          }

          this.setState({
            dialogCopy: Date.now(),
            managePitches: originalPitches,
          });
        },
      },
      {
        label: 'pl.export-pitches',
        invisible: restricted,
        onClick: async () => {
          if (!this.props.listCx.active) {
            NotifyHelper.warning({
              message_md: t('pl.no-active-pitch-list'),
            });
            return;
          }

          const activeList = this.props.listCx.active;
          if (!activeList) {
            // do nothing
            return;
          }

          const payload = selected.map((p) =>
            PitchListHelper.convertToCustomExport({
              pitch: p,
              plate_ft: this.props.machineCx.machine.plate_distance,
              video: this.props.videosCx.videos.find(
                (v) => v._id === p.video_id
              ),
            })
          );

          SessionEventsService.postEvent({
            category: 'pitch',
            tags: 'export',
            data: {
              event: 'list (checked) to CSV',
              list_id: activeList._id,
              list_name: activeList.name,
              count: payload.length,
            },
          });

          const csvString =
            await MainService.getInstance().convertJSONToCSV(payload);

          const blob = new Blob([csvString], { type: 'text/csv' });
          MiscHelper.saveAs(blob, `${slugify(activeList.name)}-checked.csv`);
        },
      },
      {
        label: 'pl.refresh-models',
        invisible:
          ONLY_ALLOW_REFRESH_ON_TRAIN || readonly || canRefresh.length === 0,
        onClick: () =>
          this.setState({
            /** only refresh checked pitches that are outdated */
            managePitches: canRefresh,
            dialogResetList: Date.now(),
          }),
        color: RADIX.COLOR.SUCCESS,
      },
      {
        label: 'common.reset-training-data',
        invisible: readonly || countTrained === 0,
        onClick: () =>
          this.setState({
            managePitches: selected,
            dialogReset: Date.now(),
          }),
        color: RADIX.COLOR.WARNING,
      },
      {
        label: 'pl.delete-pitches',
        invisible: readonly || restricted,
        onClick: () =>
          this.setState({
            managePitches: selected,
            dialogDeletePitches: Date.now(),
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return actions;
  }

  private handleDragPitchToList(
    item: IDropValue,
    monitor: DragSourceMonitor<IDropValue>
  ) {
    const target = monitor.getDropResult<IDropHandle>();
    if (item && target) {
      const pitch: IPitch = item.value;
      switch (target.container) {
        case DropContainer.PitchList: {
          const list: IPitchList = target.value.object;
          if (!list._id) {
            return;
          }

          if (list._id === pitch._parent_id) {
            return;
          }

          /** only if parent is different than the current parent => move to the new list */
          this.props.listCx.updatePitches({
            payloads: [
              {
                _id: pitch._id,
                _parent_id: list._id,
              },
            ],
            successMsg: `Pitch "${pitch.name}" moved to pitch list "${list.name}"!`,
          });
          return;
        }

        default: {
          break;
        }
      }
    }
  }

  private async onCSVChange(files: File[]): Promise<void> {
    return this.props.listsCx.updateListViaCSV(files).then((success) => {
      if (success) {
        NotifyHelper.success({
          message_md: 'CSV import processed successfully!',
        });
        return;
      }

      NotifyHelper.error({
        message_md: `CSV import was not processed successfully. ${ERROR_MSGS.CONTACT_SUPPORT}`,
      });
    });
  }

  private onChangeTrained() {
    this.changeQueue(this.state.queueDef.id);
    this.props.listCx.updateTrainingStatus();
  }

  private renderDialogs(checkedCx: ICheckedContext) {
    const readOnly = this.props.listCx.activeReadOnly();

    return (
      <PitchListsContext.Consumer>
        {(listsCx) => (
          <>
            {this.state.dialogSearch && (
              <SearchPitchesDialog
                key={this.state.dialogSearch}
                listCx={this.props.listCx}
                onClose={() => {
                  checkedCx.checkAll(false);

                  this.setState({
                    // update input w/ whatever was entered in dialog
                    searchName: this.props.listCx.searchCriteria.name,
                    searchKey: Date.now(),
                    // close the dialog
                    dialogSearch: undefined,
                  });
                }}
              />
            )}

            {this.state.dialogCard && this.props.listCx.active && (
              <ManageCardDialog
                key={this.state.dialogCard}
                identifier="PitchListManageCardDialog"
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                listCx={this.props.listCx}
                onClose={async () => {
                  this.props.machineCx.resetMSHash();
                  this.setState({ dialogCard: undefined });
                }}
              />
            )}

            {this.state.dialogCopyList && this.props.listCx.active && (
              <ManageListDialog
                key={this.state.dialogCopyList}
                identifier="PitchListCopyListDialog"
                mode="copy"
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                listsCx={listsCx}
                onCreated={() => this.setState({ dialogCopyList: undefined })}
                onClose={() => this.setState({ dialogCopyList: undefined })}
              />
            )}

            {this.state.dialogEditList && this.props.listCx.active && (
              <ManageListDialog
                key={this.state.dialogEditList}
                identifier="PitchListEditListDialog"
                mode="edit"
                authCx={this.props.authCx}
                machineCx={this.props.machineCx}
                listsCx={listsCx}
                onCreated={() => this.setState({ dialogEditList: undefined })}
                onClose={() => this.setState({ dialogEditList: undefined })}
              />
            )}

            {this.state.dialogRenameFolder && this.props.listCx.active && (
              <RenameFolderDialog
                key={this.state.dialogRenameFolder}
                list={this.props.listCx.active}
                onClose={() => this.setState({ dialogRenameFolder: undefined })}
              />
            )}

            {this.state.dialogChangeVideo && this.state.managePitches && (
              <ChangeVideoDialog
                key={this.state.dialogChangeVideo}
                pitches={this.state.managePitches}
                onClose={async (result) => {
                  if (!result) {
                    this.setState({ dialogChangeVideo: undefined });
                    return;
                  }

                  /** if selected pitch also had its video updated, update video details so the video preview updates */
                  const changedPitch = result.find(
                    (p) => p._id === this.props.aimingCx.pitch?._id
                  );
                  if (!changedPitch) {
                    this.setState({ dialogChangeVideo: undefined });
                    return;
                  }

                  /** force resend => new video needs to be rendered */
                  await this.props.aimingCx.setPitch(changedPitch);

                  this.setState({
                    dialogChangeVideo: undefined,
                  });
                }}
              />
            )}

            {this.state.dialogCopy && this.state.managePitches && (
              <CopyPitchesDialog
                key={this.state.dialogCopy}
                identifier="PitchListCopyPitchesDialog"
                authCx={this.props.authCx}
                listsCx={listsCx}
                listCx={this.props.listCx}
                title={t('common.copy-x', {
                  x: t(
                    this.state.managePitches.length === 1
                      ? 'common.pitch'
                      : 'common.pitches'
                  ),
                }).toString()}
                description={t('pl.select-pitch-list-to-copy-into').toString()}
                defaultListID={this.props.listCx.active?._id}
                pitches={this.state.managePitches}
                onCreated={() => {
                  this.setState({ dialogCopy: undefined }, () => {
                    // since you may have copied trained pitches back into this list
                    this.changeQueue(this.state.queueDef.id);
                  });
                }}
                onClose={() => this.setState({ dialogCopy: undefined })}
              />
            )}

            {this.state.dialogEdit && this.state.managePitches && (
              <EditPitchDialog
                key={this.state.dialogEdit}
                pitch={this.state.managePitches[0]}
                onClose={async () => {
                  this.props.machineCx.resetMSHash();

                  this.setState({
                    dialogEdit: undefined,
                  });
                }}
              />
            )}

            {this.state.dialogData && this.state.managePitches && (
              <PitchDataDialog
                key={this.state.dialogData}
                identifier="PitchListViewDataDialog"
                cookiesCx={this.props.cookiesCx}
                authCx={this.props.authCx}
                machine={this.props.machineCx.machine}
                pitch={this.state.managePitches[0]}
                onClose={() =>
                  this.setState({ dialogData: undefined }, () => {
                    // since you may have deleted training data that untrains a pitch
                    this.onChangeTrained();
                  })
                }
              />
            )}

            {this.state.dialogOptimize && this.state.managePitches && (
              <OptimizePitchDialog
                key={this.state.dialogOptimize}
                pitch={this.state.managePitches[0]}
                listCx={this.props.listCx}
                machineCx={this.props.machineCx}
                matchingCx={this.props.matchingCx}
                readonly={readOnly}
                onClose={() =>
                  this.setState({ dialogOptimize: undefined }, () => {
                    // since you may have modified a trained pitch to make it untrained
                    this.onChangeTrained();
                  })
                }
              />
            )}

            {this.state.dialogEditBreaks && this.state.managePitches && (
              <EditBreaksDialog
                key={this.state.dialogEditBreaks}
                pitch={this.state.managePitches[0]}
                listCx={this.props.listCx}
                machineCx={this.props.machineCx}
                matchingCx={this.props.matchingCx}
                readonly={readOnly}
                onClose={async () => {
                  this.props.machineCx.resetMSHash();
                  this.setState({ dialogEditBreaks: undefined }, () => {
                    // since you may have modified a trained pitch to make it untrained
                    this.onChangeTrained();
                  });
                }}
              />
            )}

            {this.state.dialogEditSpins && this.state.managePitches && (
              <EditSpinsDialog
                key={this.state.dialogEditSpins}
                pitch={this.state.managePitches[0]}
                listCx={this.props.listCx}
                machineCx={this.props.machineCx}
                matchingCx={this.props.matchingCx}
                readonly={readOnly}
                onClose={async () => {
                  this.props.machineCx.resetMSHash();

                  this.setState({ dialogEditSpins: undefined }, () => {
                    // since you may have modified a trained pitch to make it untrained
                    this.onChangeTrained();
                  });
                }}
              />
            )}

            {this.state.dialogReset && this.state.managePitches && (
              <ResetTrainingDialog
                key={this.state.dialogReset}
                pitches={this.state.managePitches}
                onClose={() => {
                  this.setState({ dialogReset: undefined }, () =>
                    this.onChangeTrained()
                  );
                }}
              />
            )}
          </>
        )}
      </PitchListsContext.Consumer>
    );
  }

  private renderTrainingDialog(trainingCx: ITrainingContext) {
    if (!this.state.dialogTraining) {
      return;
    }

    if (!this.state.managePitches) {
      return;
    }

    if (!this.props.matchingCx.aggReady) {
      return;
    }

    if (!this.state.managePitches) {
      return;
    }

    if (this.state.managePitches.length === 0) {
      return;
    }

    const mode = this.props.authCx.effectiveTrainingMode();

    if (mode === TrainingMode.Manual) {
      return (
        <TrainingDialog
          key={this.state.dialogTraining}
          identifier="PL-TrainingDialog"
          machineCx={this.props.machineCx}
          trainingCx={trainingCx}
          pitches={this.state.managePitches ?? []}
          threshold={this.props.machineCx.machine.training_threshold}
          onClose={() => {
            this.setState(
              {
                dialogTraining: undefined,
              },
              () => this.onEndTraining(this.state.managePitches)
            );
          }}
        />
      );
    }

    return (
      <PresetTrainingDialog
        key={this.state.dialogTraining}
        identifier="PL-PT-TrainingDialog"
        machineCx={this.props.machineCx}
        trainingCx={trainingCx}
        listCx={this.props.listCx}
        pitches={this.state.managePitches ?? []}
        onClose={() => {
          this.setState(
            {
              dialogTraining: undefined,
            },
            () => this.onEndTraining(this.state.managePitches)
          );
        }}
      />
    );
  }

  private getActions(): ITableAction[] {
    const restricted = this.props.authCx.restrictedGameStatus();
    const readonly = this.props.listCx.activeReadOnly();

    const output: ITableAction[] = [
      {
        group: ActionGroup.Primary,
        label: 'pl.open-pitch-list',
        color: RADIX.COLOR.SUCCESS,
        invisibleFn: () => this.props.listCx.active?._id !== SEARCH_ID,
        onClick: (pitch: IPitch) => {
          this.props.sectionsCx.tryChangeSection({
            trigger: 'go to list from search results',
            section: SectionName.Pitches,
            subsection: SubSectionName.List,
            fragments: [pitch._parent_id],
          });
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.view-pitch-data',
        onClick: (pitch: IPitch) =>
          this.setState({
            managePitches: [pitch],
            dialogData: Date.now(),
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'common.train-pitch',
        color: RADIX.COLOR.TRAIN_PITCH,
        invisibleFn: () =>
          env.identifier !== 'local' && !this.props.matchingCx.readyToTrain(),
        onClick: (pitch: IPitch) =>
          this.handleTrainPitches({
            ids: [pitch._id],
            promptRefresh: true,
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'main.pitch-design',
        invisibleFn: () => restricted,
        onClick: (pitch: IPitch) => {
          const refPitch = this.props.listCx.pitches.find(
            (p) => p._id === pitch._id
          );

          if (!refPitch) {
            return;
          }

          if (!readonly) {
            /** allow design context to load up the pitch data */
            this.props.sectionsCx.tryChangeSection({
              trigger: 'PitchList > context menu > update',
              section: SectionName.Pitches,
              subsection: SubSectionName.Design,
              fragments: [refPitch._id],
            });
            return;
          }

          /** empty pitch _id => pitch design will only allow saving as a new pitch */
          this.props.designCx.setReference({
            ...refPitch,
            _id: '',
          });

          this.props.sectionsCx.tryChangeSection({
            trigger: 'PitchList > context menu > readonly',
            section: SectionName.Pitches,
            subsection: SubSectionName.Design,
          });
        },
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.edit-pitch-metadata',
        invisibleFn: () => readonly || restricted,
        onClick: (pitch: IPitch) =>
          this.setState({
            managePitches: [pitch],
            dialogEdit: Date.now(),
          }),
      },
      {
        group: ActionGroup.Primary,
        label: 'pl.change-video',
        invisibleFn: () => readonly,
        onClick: (pitch: IPitch) =>
          this.setState({
            managePitches: [pitch],
            dialogChangeVideo: Date.now(),
          }),
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.optimize-pitch',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !this.props.matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !this.props.authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = this.props.matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await this.props.matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          this.setState({
            managePitches: [pitch],
            dialogOptimize: Date.now(),
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-breaks',
        disableFn: () =>
          !this.props.matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          // hide if the pitch is not trained
          const matches = this.props.matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await this.props.matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          this.setState({
            managePitches: [pitch],
            dialogEditBreaks: Date.now(),
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: 'pl.edit-spins',
        suffixIcon: <BetaIcon />,
        disableFn: () =>
          !this.props.matchingCx.readyToTrain({
            ignoreConnection: true,
            ignoreGameStatus: true,
          }),
        invisibleFn: (pitch: IPitch) => {
          if (!env.enable.auto_beta && !this.props.authCx.current.enable_beta) {
            return true;
          }

          // hide if the pitch is not trained
          const matches = this.props.matchingCx.getAggShotsByPitch(pitch);
          return !matches?.trained;
        },
        onClick: async (pitch: IPitch) => {
          await this.props.matchingCx.updatePitch(
            {
              pitch: pitch,
              includeHitterPresent: false,
              includeLowConfidence: false,
            },
            true
          );

          this.setState({
            managePitches: [pitch],
            dialogEditSpins: Date.now(),
          });
        },
      },
      {
        group: ActionGroup.Secondary,
        label: t('common.copy-x', { x: t('common.pitch') }),
        invisibleFn: () => restricted,
        onClick: (pitch: IPitch) => {
          const originalPitch = this.props.listCx.pitches.find(
            (p) => p._id === pitch._id
          );
          if (!originalPitch) {
            NotifyHelper.error({
              message_md:
                'There was an error, please try again after refreshing.',
            });
            console.error({
              event: `${COMPONENT_NAME}: failed to find selected pitch in active context.`,
              pitch,
            });
            return;
          }

          this.setState({
            managePitches: [originalPitch],
            dialogCopy: Date.now(),
          });
        },
      },
      {
        group: ActionGroup.Tertiary,
        label: 'pl.refresh-model',
        invisibleFn: (pitch: IPitch) =>
          ONLY_ALLOW_REFRESH_ON_TRAIN ||
          readonly ||
          !this.props.matchingCx.readyToRefresh(pitch),
        onClick: (pitch: IPitch) => {
          this.setState({
            managePitches: [pitch],
            dialogResetList: Date.now(),
          });
        },
        color: RADIX.COLOR.SUCCESS,
      },
      {
        group: ActionGroup.Tertiary,
        label: 'common.reset-training-data',
        invisibleFn: (pitch: IPitch) => {
          if (readonly) {
            return true;
          }

          // hide if the pitch has no shots
          const matches = this.props.matchingCx.getAggShotsByPitch(pitch);
          return !matches || matches.total === 0;
        },
        onClick: (pitch: IPitch) => {
          this.setState({
            managePitches: [pitch],
            dialogReset: Date.now(),
          });
        },
        color: RADIX.COLOR.WARNING,
      },
      {
        group: ActionGroup.Tertiary,
        label: t('common.delete-x', { x: t('common.pitch') }),
        invisibleFn: () => readonly || restricted,
        onClick: (pitch: IPitch) =>
          this.setState({
            managePitches: [pitch],
            dialogDeletePitches: Date.now(),
          }),
        color: RADIX.COLOR.DANGER,
      },
    ];

    return output;
  }

  private getTrainingStatus(trained: boolean): IPitch[] {
    return this.props.listCx.pitches.filter(
      (p) => trained === this.props.matchingCx.isPitchTrained(p)
    );
  }

  private renderHeader() {
    const isSearch = this.props.listCx.active?._id === SEARCH_ID;
    const untrained = this.getTrainingStatus(false);

    return (
      <Box pb={RADIX.BOX.PAD.SM}>
        {isSearch ? (
          <PitchesHeader />
        ) : (
          <Header
            list={this.props.listCx.active}
            actions={this.getListActions()}
            mainAction={
              untrained.length === 0
                ? // don't allow train all from search view
                  undefined
                : {
                    label: t('pl.train-n-new-x', {
                      n: untrained.length,
                      x: t(
                        untrained.length === 1
                          ? 'common.pitch'
                          : 'common.pitches'
                      ).toLowerCase(),
                    }).toString(),
                    disabled: !this.props.matchingCx.readyToTrain(),
                    variant: 'soft',
                    color: RADIX.COLOR.TRAIN_PITCH,
                    className: 'text-titlecase',
                    onClick: () =>
                      this.handleTrainPitches({
                        ids: untrained.map((p) => p._id),
                        promptRefresh: true,
                      }),
                  }
            }
          />
        )}
      </Box>
    );
  }

  private renderFireTagsControls() {
    return (
      <Box>
        <CommonTextInput
          id="pitch-list-tags"
          value={this.state.tags.toUpperCase()}
          placeholder="Tags (comma-delimited)"
          onChange={(v) => this.setState({ tags: v?.toUpperCase() ?? '' })}
        />
      </Box>
    );
  }

  private renderBody() {
    const pagination: ITablePageable = {
      identifier: IDENTIFIER,
      enablePagination: true,
      total: this.props.listCx.pitches.length,
      pageSizes: PAGE_SIZES,
    };

    const sort: ITableSortable = {
      enableSort: true,
      beforeSortFn: async () => {
        if (this.props.listCx.active?._id !== SEARCH_ID) {
          return;
        }

        if (this.props.listCx.searchCriteria.limit === MAX_SEARCH_LIMIT) {
          return;
        }

        // setting the search will cause the limit to be set to the max
        await this.props.listCx.setSearchCriteria(
          this.props.listCx.searchCriteria
        );
      },
    };

    const reorder: ITableReorder = {
      collection: 'pitches',
      mappingFn: (item: IPitch) => {
        return {
          label: item.name ?? t('pl.unnamed-pitch'),
          value: item._id,
        };
      },
    };

    const select: ITableSelectable = {
      enableSelect: true,
      blockSelect: this.props.matchingCx.loading,
      afterChangeSelected: (model: IPitch | undefined) =>
        this.afterChangeSelected(model),
    };

    const listener: ITableListener = {
      enableListener: this.props.globalCx.dialogs.length === 0,
      onKeyActions: {
        Space: async (pitch: IPitch) => {
          if (pitch) {
            await this.setPitchResetLocation(pitch);

            this.setState({
              dialogData: Date.now(),
            });
          }
        },

        Delete: (pitch: IPitch) => {
          if (!this.props.listCx.activeReadOnly() && pitch) {
            this.setState({
              managePitches: [pitch],
              dialogDeletePitches: Date.now(),
            });
          }
        },

        F2: (pitch: IPitch) => {
          if (!this.props.listCx.activeReadOnly() && pitch) {
            this.setState({
              managePitches: [pitch],
              dialogEdit: Date.now(),
            });
          }
        },
      },
    };

    return (
      <FlexTableWrapper
        gap={RADIX.FLEX.GAP.SECTION}
        header={
          <>
            {this.renderHeader()}
            <ActiveCalibrationModelWarning showSettingsButton />
          </>
        }
        table={
          <CheckedProvider data={this.props.listCx.pitches}>
            <CheckedContext.Consumer>
              {(checkedCx) => (
                <>
                  <CommonTable
                    id={COMPONENT_NAME}
                    tableCx={this.props.tableCx}
                    checkedCx={checkedCx}
                    checkedMenuActions={this.getCheckedMenuActions()}
                    toolbarContent={this.renderToolbar(checkedCx)}
                    displayColumns={this.BASE_COLUMNS}
                    displayData={this.props.listCx.pitches}
                    // dragType={DragItem.Pitch}
                    // dragEndFn={this.handleDragPitchToList}
                    rowClassNameFn={(pitch: IPitch) =>
                      this.props.matchingCx.isPitchTrained(pitch)
                        ? 'Sendable'
                        : 'Trainable'
                    }
                    afterCheckOne={(changed: IPitch, checked: boolean) => {
                      this.changeQueue(
                        this.state.queueDef.id,
                        checked ? { add: changed } : { remove: changed }
                      );
                    }}
                    // regardless of the mode, rebuild the queue
                    afterCheckAll={() =>
                      this.changeQueue(this.state.queueDef.id)
                    }
                    checkboxColumnIndex={1}
                    loading={this.props.listCx.loading}
                    {...pagination}
                    {...listener}
                    {...reorder}
                    {...select}
                    {...sort}
                    vFlex
                  />

                  {this.renderDialogs(checkedCx)}
                </>
              )}
            </CheckedContext.Consumer>
          </CheckedProvider>
        }
        footer={
          <>
            {this.renderTableFooter()}

            {ENABLE_LOGS && <CommonLogs logs={this.state.logs} />}
          </>
        }
      />
    );
  }

  private renderToolbar(checkedCx: ICheckedContext): React.ReactNode {
    return (
      <Flex direction="column" gap={RADIX.FLEX.GAP.SM}>
        <Flex gap={RADIX.FLEX.GAP.SM}>
          <Grid flexGrow="1" columns="3" gap={RADIX.FLEX.GAP.SM}>
            <Box>{this.props.hittersCx.getInput('level')}</Box>
            <Box>{this.props.hittersCx.getInput('hitter')}</Box>
            <Box>
              <CommonTextInput
                key={this.state.searchKey}
                id="pitch-list-search"
                name="name"
                placeholder="common.search-placeholder"
                value={this.state.searchName ?? ''}
                disabled={this.props.listCx.loading}
                onChange={(v) => this.setState({ searchName: v })}
                onKeyUp={(e) => {
                  if (e.code === 'Enter') {
                    this.performSearch(checkedCx);
                  }
                }}
              />
            </Box>
          </Grid>

          <Box>
            <CommonTableButton
              label="common.search"
              className="btn-block"
              variant="soft"
              onClick={() => this.performSearch(checkedCx)}
            />
          </Box>
          <Box>
            <CommonTableButton
              label="common.advanced-filters"
              className="btn-block"
              variant="soft"
              onClick={() =>
                this.setState({
                  dialogSearch: Date.now(),
                })
              }
            />
          </Box>
        </Flex>

        {env.enable.fire_tags && (
          <Flex gap={RADIX.FLEX.GAP.SM}>{this.renderFireTagsControls()}</Flex>
        )}
      </Flex>
    );
  }

  private performSearch(checkedCx: ICheckedContext) {
    checkedCx.checkAll(false);

    this.props.listCx.setSearchCriteria({
      ...this.props.listCx.searchCriteria,
      name: this.state.searchName,
    });
  }

  private renderSidebar() {
    if (!this.props.aimingCx.pitch) {
      return;
    }

    return (
      <PitchListSidebar
        hittersCx={this.props.hittersCx}
        machineCx={this.props.machineCx}
        matchingCx={this.props.matchingCx}
        aimingCx={this.props.aimingCx}
        videosCx={this.props.videosCx}
        training={!!this.state.dialogTraining}
        onMatchesChanged={(newPitch) => {
          if (newPitch) {
            return;
          }

          this.sendSelected('onMatchesChanged', true);
        }}
        onVideoChanged={async (video_id) => {
          const pitch = this.props.aimingCx.pitch;
          if (!pitch) {
            return;
          }

          const changed = pitch.video_id !== video_id;
          if (!changed) {
            return;
          }

          // check for video errors if necessary
          if (video_id) {
            const video = this.props.videosCx.videos.find(
              (v) => v._id === video_id
            );

            if (!video) {
              NotifyHelper.error({
                message_md: `Video \`${video_id}\` does not exist in context. Please try again.`,
              });
              return;
            }

            const warnings = VideoHelper.validateSelection({
              pitch_name: pitch.name,
              position: pitch.bs,
              video: video,
            });

            // only notify the first error that comes up
            if (warnings.length > 0) {
              NotifyHelper.warning({
                message_md: warnings[0],
                inbox: true,
              });
            }
          }

          const results = await this.props.listCx.updatePitches({
            payloads: [
              {
                _id: pitch._id,
                video_id: video_id ?? '',
              },
            ],
          });
          const updatedPitch = results?.find((p) => p._id === pitch._id);
          await this.props.aimingCx.setPitch(updatedPitch);
        }}
      />
    );
  }

  render() {
    return (
      <ErrorBoundary componentName={COMPONENT_NAME}>
        <Box className={RADIX.VFLEX.WRAPPER} flexGrow="1">
          <CommonContentWithSidebar
            left={this.renderBody()}
            right={this.renderSidebar()}
            hideSidebar={!this.props.aimingCx.pitch}
            vFlex
          />
        </Box>

        <TrainingProvider
          cookiesCx={this.props.cookiesCx}
          mode={this.props.authCx.effectiveTrainingMode()}
          afterTrainingMsg={this.afterTrainingMsg}
        >
          <TrainingContext.Consumer>
            {(trainingCx) => this.renderTrainingDialog(trainingCx)}
          </TrainingContext.Consumer>
        </TrainingProvider>

        {this.state.dialogResetList && (
          <RefreshListDialog
            pitches={this.state.managePitches ?? []}
            onRefresh={() => {
              if (!this.state.managePitches) {
                return;
              }

              this.props.listCx.rebuild(
                this.state.managePitches.map((p) => p._id)
              );
              this.onChangeTrained();
            }}
          />
        )}

        {this.state.dialogDeletePitches && (
          <DeletePitchesDialog
            key={this.state.dialogDeletePitches}
            pitches={this.state.managePitches ?? []}
            onDelete={() => {
              this.props.machineCx.resetMSHash();
              // deleted pitch may still be in the queue
              this.onChangeTrained();
            }}
          />
        )}

        {this.state.dialogDeleteList && (
          <PitchListsContext.Consumer>
            {(listsCx) => (
              <CommonConfirmationDialog
                key={this.state.dialogDeleteList}
                identifier="DeleteListDialog"
                title={t('common.delete-x', {
                  x: t('common.pitch-list'),
                }).toString()}
                description={t('common.confirm-delete-x', {
                  x: this.props.listCx.active?.name ?? '(no name)',
                }).toString()}
                action={{
                  label: 'common.delete',
                  color: RADIX.COLOR.DANGER,
                  onClick: () => {
                    if (!this.props.listCx.active) {
                      return;
                    }

                    listsCx
                      .deleteLists([this.props.listCx.active._id])
                      .then((success) => {
                        if (success) {
                          this.props.sectionsCx.tryGoHome();
                        }
                      });
                  },
                }}
              />
            )}
          </PitchListsContext.Consumer>
        )}

        <CommonSimpleFileUploader
          ref={(elem) => (this.fileInput = elem as CommonSimpleFileUploader)}
          id="pitch-list-uploader"
          acceptedTypes={['text/csv']}
          notifyMode="aggregate"
          onChange={(files) => this.onCSVChange(files)}
          hidden
        />

        <RemoteKeydownListener
          machineCx={this.props.machineCx}
          suspendFn={() => this.props.globalCx.dialogs.length > 0}
          onFire={() => {
            const mode = this.localMachineButtonMode();
            switch (mode) {
              case MachineButtonMode.Fire: {
                this.fireButton?.performFire(
                  'manual',
                  'clicker event: fire',
                  this.props.machineCx.machine.remote_hotkeys?.delay_ms
                );
                return;
              }

              case MachineButtonMode.Send: {
                this.sendSelected('remote control', false);
                return;
              }

              default: {
                return;
              }
            }
          }}
          onNext={() => {
            this.changeActivePitch({
              delta: 1,
              delay_ms: DELAY_REMOTE_SEND_MS,
              cascadeSend: true,
            });
          }}
          onPrev={() => {
            this.changeActivePitch({
              delta: -1,
              delay_ms: DELAY_REMOTE_SEND_MS,
              cascadeSend: true,
            });
          }}
        />
      </ErrorBoundary>
    );
  }

  private localMachineButtonMode() {
    return this.props.matchingCx.machineButtonMode({
      pitch: this.props.aimingCx.pitch,
      requireTraining: true,
      requireSend: this.props.aimingCx.checkRequireSend(),
      awaitingResend: this.fireButton?.getAwaitingResend(),
    });
  }
}
