import {
  BallStatusMsgType,
  CameraSource,
  ContextName,
  MachineProcess,
  WsMsgType,
} from '../enums/machine-msg.enum';
import { GameStatus } from '../enums/mlb.enums';
import { RadixColor } from '../enums/radix-ui';
import { IMachineConfig } from './i-machine-config';
import { IMachineState } from './i-machine-state';
import { IMachineErrorMsg, IUserErrorMsg } from './machine-msg/i-error';
import { IFireMsg } from './machine-msg/i-fire';
import {
  IMachineStatusMsg,
  IMachineStatusUpdateMsg,
} from './machine-msg/i-machine-status';
import { IProjectorSfxMsg } from './machine-msg/i-projector-sfx';
import {
  IPitchPreviewOverlayMsg,
  IPitchRecapOverlayMsg,
} from './machine-msg/i-projector-text-overlay';
import { IQueueUserMsg } from './machine-msg/i-queue-user';
import { ISpecialMstargetMsg } from './machine-msg/i-special-mstarget';
import { IMachineShot } from './training/i-machine-shot';
import { IManualShot } from './training/i-manual-shot';
import { IMSBSShot } from './training/i-msbs-shot';
import { IRapsodoShot } from './training/i-rapsodo-shot';
import { ITrackmanShot } from './training/i-trackman-shot';

export interface IWSMsg {
  type: WsMsgType;
  machineID?: string;
  // what caused the message to be sent, e.g. foot pedal vs mouse click
  trigger?: string;
  data:
    | IBallStatusMsg
    | ICalibrateRequestMsg
    | ICalibrateResponseMsg
    | ICameraStreamRequestMsg
    | ICameraStreamResponseMsg
    | ICustomRequestMsg
    | IContextMsg
    | IDropBallMsg
    | IUserErrorMsg
    | IFireMsg
    | IFireResponseMsg
    | IFirmwareResponseMsg
    | IFirmwareWriteMsg
    | IGameStatusMsg
    | IMachineErrorMsg
    | IMachineStateMsg
    | IMachineStatusMsg
    | IMachineStatusUpdateMsg
    | IOverlaySummaryRequestMsg
    | IOverlaySummaryResponseMsg
    | IPitchPreviewOverlayMsg
    | IPitchRecapOverlayMsg
    | IPreviewVideoMsg
    | IProcessQueryResponseMsg
    | IQueueMsg
    | IQueueUserMsg
    | IProjectorSfxMsg
    | IReadLogMsg
    | IReadLogResponseMsg
    | IReadyMsg
    | ISpecialMstargetMsg
    | ISystemRebootResponseMsg
    | ITrainingMsg
    | IUserEventMsg;
}

export interface ITrainingMsg {
  /** true => successful, false => failure, undefined => intermediate update only */
  success?: boolean;

  /** true => server timed out without receiving any detection, prompt user to check tracking device settings */
  detectionFailed?: boolean;

  /** provided if success === true */
  shot?: IMachineShot;
  /** provided if success === false or if success === undefined */
  message?: string;

  msbs?: IMSBSShot;
  rapsodo?: IRapsodoShot;
  trackman?: ITrackmanShot;
  manual?: IManualShot;
}

/** waiting user sends a waiting-request with their username and session id
 * active user sends an active-accept or active-reject with the same waiting session id
 */
export interface IQueueMsg {
  action:
    | 'waiting-request'
    | '2fa-accept'
    | '2fa-reject'
    | 'active-accept'
    | 'active-reject';

  /** username - server will populate this automatically when requesting */
  waiting_user?: string;

  /** session id - server will populate this automatically when requesting */
  waiting_session?: string;
}

export interface IGameStatusMsg {
  status: GameStatus;
}

export interface IUserEventMsg {
  type: 'open' | 'close';
}

/** machine should send this whenever ball_count changes, whether from true => false or vice-versa */
export interface IBallStatusMsg {
  type: BallStatusMsgType;
  ball_count: number;
  already_present: boolean;

  /**
   * only set/exists when raising the ball-status from within the app itself,
   * i.e. from drop ball buttons, for opening without affecting button display in the sidebar footer
   */
  _local?: boolean;
}

export interface IMachineResetMsg {
  /** machine idle time in minutes */
  idle_time: number;
  type: string;
}

export interface IPreviewVideoMsg {
  px: number;
  pz: number;
  video_uuid: string;
}

export interface IMachineStateCurrent extends IMachineState {
  ball_count: number;
  dead_loading_procs: string[];
  od_busy: boolean;
  // delta time, how much the fire will wait from start of projector playback
  projector_dt: number;
  video_uuid: string;

  // should correspond to the status indicator on screen
  proj_label: string;
}

export interface IMachineStateMsg extends IMachineState {
  video_uuid: string;

  // set right before sending to machine, used for matching against incoming r2f messages
  hash?: string;

  // when set, machine state is not validated before sending to machine (e.g. for emptying carousel), use with caution
  force?: boolean;
}

export interface IDropBallMsg {
  // only used by node server for routing the instruction to the appropriate machine (e.g. if an admin remotely triggers for a machine they are not active on)
  machineID: string;
}

export interface IFireResponseMsg {
  status: boolean;
  message?: string;
}

export interface IFirmwareResponseMsg {
  type: WsMsgType;
  success: boolean;

  /** e.g. commit id value, or an error message of why the machine failed */
  message?: string;

  /** e.g. machine and default config values */
  result?: IMachineConfig;
}

export interface IFirmwareWriteMsg {
  value: any;
  preset?: string;
}

export interface ICustomRequestMsg {
  // e.g. specific submodules that need to act on the message value
  targets?: string[];
  value: any;
}

interface IBooleanMachineState {
  w1: boolean;
  w2: boolean;
  w3: boolean;
  a1: boolean;
  a2: boolean;
  a3: boolean;
  qw: boolean;
  qx: boolean;
  qy: boolean;
  qz: boolean;
  tilt: boolean;
  yaw: boolean;
  px: boolean;
  py: boolean;
  pz: boolean;
}

export interface IReadyData extends IBooleanMachineState {
  video_uuid: boolean;
  training: boolean;
}

export interface IReadyMsg {
  timeout_processes?: string[];
  timeout_duration_sec?: number;
  // machine should record this value and append it to its r2f messages, for filtering r2f messages that are forwarded to the web app
  target_hash?: string;
  status: boolean;
  data: IReadyData;
}

interface IBaseReadLog {
  machineID: string;
  session: string;
}

export interface IReadLogMsg extends IBaseReadLog {
  processes: MachineProcess[];
  // how many lines from the tail of each file to include, empty or -1 will fetch everything
  limit?: number;
}

export interface IReadLogResponseMsg extends IBaseReadLog {
  files: { process: MachineProcess; content: string }[];
}

export interface ICameraStreamResponseMsg {
  source: CameraSource;
  // expecting ISO datetime string
  timestamp: string;
  // base64 jpg
  image: string;
  // like 'png' or 'jpg'
  format: string;
}

// for web app or server to instruct the machine to start/stop streaming from a particular source
export interface ICameraStreamRequestMsg {
  machineID: string;
  source: CameraSource;
  // true => start (if not started), false => stop (if not stopped)
  enable: boolean;
}

export enum CalibrateProc {
  All = 'all',
  Gantry = 'gantry',
  Alphas = 'alphas',
  Projector = 'projector',
  ProjectorOutline = 'projector_outline',
  ProjectorRulers = 'projector_rulers',
}

export interface ICalibrateRequestMsg {
  procs: CalibrateProc[];
}

export interface ICalibrateResponseMsg {
  calibrate_success_list: boolean[];
  can_calibrate?: boolean;
  status: boolean;
  timestamp: number;
}

export interface ICalibrateProgressProcess {
  name: string;
  success?: boolean;
  can_calibrate?: boolean;
}

export interface ICalibrateProgressMsg {
  timestamp: string;
  progress_list: ICalibrateProgressProcess[];
}

export interface IOverlaySummaryRequestMsg {}

export interface IOverlaySummaryResponseMsg {
  overlay_ids: string[];
  status: boolean;
}

export interface IProcessStatus {
  name: string;
  alive: boolean;
  can_calibrate: boolean;
  can_health_check: boolean;
}

export interface IProcessQueryResponseMsg {
  processes: IProcessStatus[];
  status: boolean;
}

/**
 * reboot on entire OS
 */
export interface ISoftRebootResponseMsg {
  success: boolean;
}

/**
 * restarts all processes
 */
export interface ISystemRebootResponseMsg {
  success: boolean;
  failed_list: string[];
}

export interface ITwoFactorMsg {
  /** provide true to clear existing message */
  clear: boolean;

  /**
   * node server will handle populating the code when it passes it along to the machine
   * react app should provide the code if it's a user's attempt
   * */
  code: string;
}

export interface IAnnouncementMsg {
  message: string;
  color?: RadixColor;

  /**
   * undefined => default delay
   * 0 => disable auto-dismiss
   */
  delay_ms?: number;
}

export interface IRulerMsg {
  show: boolean;
}

export interface IProjectorAdjustmentMsg {
  delta_v?: number;
  delta_h?: number;
  reset_v?: boolean;
  reset_h?: boolean;
}

export interface IContextMsg {
  context: ContextName;
  action: 'update' | 'clear';
}
