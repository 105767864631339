import { Card, ScrollArea, Strong, Table, Text } from '@radix-ui/themes';
import {
  BAD_PREDICTION,
  GOOD_PREDICTION,
  ModelPredictionHelper,
} from 'classes/helpers/model-prediction.helper';
import { CommonDetails } from 'components/common/details';
import { ErrorBoundary } from 'components/common/error-boundary';
import { ActivateModelButton } from 'components/sections/admin-portal/machine-models/activate-model-button';
import { MachineModelsContext } from 'contexts/admin/machine-models.context';
import { MachinesContext } from 'contexts/admin/machines.context';
import { parseISO } from 'date-fns';
import { format } from 'date-fns-tz';
import { LOCAL_DATETIME_FORMAT, LOCAL_TIMEZONE } from 'enums/env';
import { ArrayHelper } from 'lib_ts/classes/array.helper';
import { FT_TO_INCHES } from 'lib_ts/classes/math.utilities';
import { MiscHelper } from 'lib_ts/classes/misc.helper';
import { RADIX, RadixColor } from 'lib_ts/enums/radix-ui';
import { IEvalModelResult } from 'lib_ts/interfaces/modelling/i-eval-models';
import React from 'react';

const PRECISION_DECIMALS = 4;
const PRECISION_COEFF = Math.pow(10, PRECISION_DECIMALS);

interface INumericEntry {
  value: number;
  color: RadixColor;
}
interface IRowSummary {
  root: string;
  name: string;

  /** note: do not use in conjunction with text_values */
  num_values?: INumericEntry[];

  /** note: do not use in conjunction with num_values */
  text_values?: string[];

  // i.e. best in the context of errors
  minValue?: number;

  // i.e. worst in the context of errors
  maxValue?: number;
}

interface IProps {
  metrics: IEvalModelResult[];
}

interface IState {
  attributes: IRowSummary[];
}

export class SummarizeModelMetrics extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      attributes: [],
    };

    this.updateAttributes = this.updateAttributes.bind(this);
  }

  componentDidMount(): void {
    this.updateAttributes();
  }

  private updateAttributes() {
    const models = this.props.metrics;

    const modelValues: IRowSummary[] = [
      {
        root: 'Model',
        name: 'MachineID',
        text_values: models.map((m) => m.machineID ?? '???'),
      },
      {
        root: 'Model',
        name: 'Ball Type',
        text_values: ArrayHelper.unique(models.map((m) => m.ball_type)),
      },
    ];

    const BEST_LABEL = 'Mean Best 90%';
    const WORST_LABEL = 'Mean Worst 10%';

    const errorValues: IRowSummary[] = [
      {
        root: BEST_LABEL,
        name: 'Abs. Error vx',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.vx;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vx
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vx
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };

          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error vy',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.vy;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vy
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vy
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error vz',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.vz;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vz
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vz
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error wx',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.wx;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wx
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wx
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error wy',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.wy;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wy
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wy
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error wz',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_best_90p.wz;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wz
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wz
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error break_x_in',
        num_values: models.map((m) => {
          const inchValue =
            m.model_performance.mean_best_90p.break_x_ft * FT_TO_INCHES;

          const result: INumericEntry = {
            value: inchValue,
            color:
              inchValue > BAD_PREDICTION.break_x_ft * FT_TO_INCHES
                ? RADIX.COLOR.DANGER
                : inchValue < GOOD_PREDICTION.break_x_ft * FT_TO_INCHES
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: BEST_LABEL,
        name: 'Abs. Error break_z_in',
        num_values: models.map((m) => {
          const inchValue =
            m.model_performance.mean_best_90p.break_z_ft * FT_TO_INCHES;

          const result: INumericEntry = {
            value: inchValue,
            color:
              inchValue > BAD_PREDICTION.break_z_ft * FT_TO_INCHES
                ? RADIX.COLOR.DANGER
                : inchValue < GOOD_PREDICTION.break_z_ft * FT_TO_INCHES
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error vx',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.vx;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vx
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vx
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error vy',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.vy;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vy
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vy
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error vz',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.vz;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.vz
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.vz
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error wx',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.wx;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wx
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wx
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error wy',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.wy;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wy
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wy
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error wz',
        num_values: models.map((m) => {
          const v = m.model_performance.mean_worst_10p.wz;
          const result: INumericEntry = {
            value: v,
            color:
              v > BAD_PREDICTION.wz
                ? RADIX.COLOR.DANGER
                : v < GOOD_PREDICTION.wz
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error break_x_in',
        num_values: models.map((m) => {
          const inchValue =
            m.model_performance.mean_worst_10p.break_x_ft * FT_TO_INCHES;

          const result: INumericEntry = {
            value: inchValue,
            color:
              inchValue > BAD_PREDICTION.break_x_ft * FT_TO_INCHES
                ? RADIX.COLOR.DANGER
                : inchValue < GOOD_PREDICTION.break_x_ft * FT_TO_INCHES
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
      {
        root: WORST_LABEL,
        name: 'Abs. Error break_z_in',
        num_values: models.map((m) => {
          const inchValue =
            m.model_performance.mean_worst_10p.break_z_ft * FT_TO_INCHES;
          const result: INumericEntry = {
            value: inchValue,
            color:
              inchValue > BAD_PREDICTION.break_z_ft * FT_TO_INCHES
                ? RADIX.COLOR.DANGER
                : inchValue < GOOD_PREDICTION.break_z_ft * FT_TO_INCHES
                ? RADIX.COLOR.SUCCESS
                : RADIX.COLOR.WARNING,
          };
          return result;
        }),
      },
    ];

    /** for errors, smaller is better */
    errorValues.forEach((e) => {
      if (!e.num_values) {
        return;
      }

      e.num_values.forEach(
        (v) =>
          (v.value = Math.round(v.value * PRECISION_COEFF) / PRECISION_COEFF)
      );

      if (e.num_values.length > 0) {
        e.minValue = MiscHelper.getMin(e.num_values.map((v) => v.value));
        e.maxValue = MiscHelper.getMax(e.num_values.map((v) => v.value));
      }
    });

    const ratingValues: IRowSummary[] = [
      {
        root: '%',
        name: 'Model Performance',
        num_values: models.map((m) => {
          const result: INumericEntry = {
            value: Math.round(
              ModelPredictionHelper.overallPercent(m.model_performance) * 100
            ),
            color: RADIX.COLOR.NEUTRAL,
          };
          return result;
        }),
      },
    ];

    /** for ratings, bigger is better */
    ratingValues.forEach((r) => {
      if (!r.num_values) {
        return;
      }

      if (r.num_values.length > 0) {
        r.minValue = MiscHelper.getMax(r.num_values.map((v) => v.value));
        r.maxValue = MiscHelper.getMin(r.num_values.map((v) => v.value));
      }
    });

    this.setState({
      attributes: [
        ...modelValues,
        ...errorValues,
        ...ratingValues,
        // additional stats can go here, e.g. if larger is better for those values
      ],
    });
  }

  render() {
    const models = this.props.metrics;

    return (
      <ErrorBoundary componentName="SummarizeMetrics">
        <Card>
          <CommonDetails summary="Summary" defaultOpen>
            <ScrollArea scrollbars="horizontal">
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>Attribute</Table.ColumnHeaderCell>

                    {models.map((m, i) => {
                      const datestamp = format(
                        parseISO(m.created),
                        LOCAL_DATETIME_FORMAT,
                        {
                          timeZone: LOCAL_TIMEZONE,
                        }
                      );

                      return (
                        <Table.ColumnHeaderCell
                          key={`model-${m.model_id}`}
                          title={`Created on ${datestamp}`}
                          align="right"
                        >
                          {m.model_name ?? `Model ${i + 1}`}
                        </Table.ColumnHeaderCell>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body className="font-mono">
                  <Text size={RADIX.TEXT.SIZE.XS}></Text>
                  {this.state.attributes.map((row, iRow) => (
                    <Table.Row key={iRow}>
                      <Table.Cell>
                        {row.root} {row.name}
                      </Table.Cell>

                      {row.num_values &&
                        row.num_values.map((entry, iValue) => {
                          const isMinimum =
                            row.minValue && entry.value <= row.minValue;

                          return (
                            <Table.Cell
                              key={`row-${iRow}-value-${iValue}`}
                              align="right"
                            >
                              {isMinimum ? (
                                <Text color={entry.color}>
                                  <Strong>{entry.value}</Strong>
                                </Text>
                              ) : (
                                <Text color={entry.color}>{entry.value}</Text>
                              )}
                            </Table.Cell>
                          );
                        })}

                      {row.text_values &&
                        row.text_values.map((value, iValue) => {
                          return (
                            <Table.Cell
                              key={`row-${iRow}-value-${iValue}`}
                              align="right"
                            >
                              {value}
                            </Table.Cell>
                          );
                        })}
                    </Table.Row>
                  ))}
                </Table.Body>
                <tfoot>
                  <Table.Row>
                    <Table.Cell>&nbsp;</Table.Cell>

                    {models.map((m) => (
                      <Table.Cell
                        key={`activate-model-${m.model_id}`}
                        align="right"
                      >
                        {m && (
                          <MachinesContext.Consumer>
                            {(machinesCx) => (
                              <MachineModelsContext.Consumer>
                                {(modelsCx) => (
                                  <ActivateModelButton
                                    machinesCx={machinesCx}
                                    machineModelsCx={modelsCx}
                                    modelID={m.model_id}
                                  />
                                )}
                              </MachineModelsContext.Consumer>
                            )}
                          </MachinesContext.Consumer>
                        )}
                      </Table.Cell>
                    ))}
                  </Table.Row>
                </tfoot>
              </Table.Root>
            </ScrollArea>
          </CommonDetails>
        </Card>
      </ErrorBoundary>
    );
  }
}
